import React, { useEffect, useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { useSmartSheetsAPI, useCiscoAPI } from "@hooks";
import { AccessLevelEnum, IListSheetsItem, IListSheetsResponse, IListReportsItem, IListDashboardsItem } from "@models";
import { SmartSheetsAPI } from "@services";
import { SmartSheetLogo } from "@assets";
import { Loader, SelectSheetPermissions, SignOutButton } from "@components";
import { AppContext } from "@contexts";
import { checkCannotShare, updateRecentSheets } from "@helpers";
import { TabsComponent } from "./TabsComponent/TabsComponent";
import { SearchComponent } from "./SearchComponent/SearchComponent";
import { SheetItemComponent } from "./SheetItemComponent/SheetItemComponent";

import Pagination from "../Pagination/Pagination";

import "./SelectSheetComponent.scss";

export const SelectSheetComponent: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const context = useContext(AppContext);
  const history = useHistory();
  const [sheetList, setSheetList] = useState<IListSheetsItem[]>([]);
  const { getToken, getRecentSheets, getAllSheets, shareSheet, getPaginatedSheets, getPaginatedReports, getPaginatedDashboards, getSheet } = useSmartSheetsAPI();
  const { getAPIMeeting, getAPIMeetingParticipants, getAPIParticipants, getAPIRooms, getAPIMemberships } = useCiscoAPI();
  const [loading, setLoading] = useState(false);

  // FOR TESTING
  // useEffect(() => {
  //   const test = async () => {
  //     const res = await SmartSheetsAPI.getListSheets("");
  //   }; 
  //   test(); 
  // }, [])

  useEffect(() => {
    if (context?.webex) context?.webex?.handleClearShare?.();
    const roomId = localStorage.getItem("spaceId") || "";

    context.setRoomUsers([]);

    if (roomId) {
      getAPIMemberships(roomId, localStorage.getItem("cisco_access_token") || "").then((data) => {
        context.setRoomUsers(data.items?.map((item) => item.personEmail));
      });
    }
  }, []);

  async function fetchParticipants(ciscoAccessToken: string) {
    await getAPIParticipants(ciscoAccessToken)
      .then((result) => {
        const { items: meetingParticipants } = result;
        context.setMeetingUsers(meetingParticipants);
        console.log("meeting participants", meetingParticipants);
        setLoading(false);
      })
      .catch(() => {
        context.setMeetingUsers([]);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    try {
      const ciscoAccessToken = localStorage.getItem("cisco_access_token");
      // check if they exist before beginning
      if (ciscoAccessToken) {
        fetchParticipants(ciscoAccessToken);
      }
    } catch (error) {
      console.error(error);
    }
  }, [context.webex.user, context.webex.meeting]);

  React.useEffect(() => {
    const getSheets = async () => {
      setLoading(true);
      try {
        if (activeTab === 0) {
          // GET - recent sheets
          const recentSheets = await getRecentSheets();
          context.setRecentSheets(recentSheets);
        }
      } catch (e) {
        console.error("Cannot get recent sheets", e);
      }
      setLoading(false);
    };
    console.log(localStorage.getItem("smartsheet_access_token"));
    if (localStorage.getItem("smartsheet_access_token")) {
      
      getSheets();
    }
  }, [activeTab]);

  // when the user selects a sheet from the list, navigate to the permission page
  const onSheetSelect = (sheetData: IListSheetsItem) => {
    context.setActiveSheet(sheetData);
    updateRecentSheets(sheetData);
  };

  const onReportSelect = (reportData: IListReportsItem) => {
    context.setActiveReport(reportData);
    updateRecentSheets(reportData);
  }

  const onDashboardSelect = (dashboardData: IListDashboardsItem) => {
    console.log(dashboardData);
    context.setActiveDashboard(dashboardData);
    updateRecentSheets(dashboardData);
  }

  React.useEffect(() => {
    console.log(context);
    if (context.activeSheet) {
      if (checkCannotShare(context.activeSheet.accessLevel)) history.push("/permission-error");
      else history.push("/permissions");
    }

    if (context.activeReport) {
      console.log(context.activeReport);
      if (checkCannotShare(context.activeReport.accessLevel)) history.push("/permission-error");
      else history.push("/permissions");
    }

    if (context.activeDashboard) {
      console.log(context.activeDashboard);
      if (checkCannotShare(context.activeDashboard.accessLevel)) history.push("/permission-error");
      else history.push("/permissions");
    }
    // if (context.activeSheet && context.meetingUsers.length > 0) {
    //   history.push("/permissions");
    // } else {
    //   console.log("Meeting users list is empty");
    // }
  }, [context.activeSheet, context.activeReport, context.activeDashboard]);

  if (!context.recentSheets) return <div className="default-dark-font-1"> No Recents Sheets... </div>;

  if (loading) return <Loader />

  const RecentSheets = (): React.ReactNode => {
    return context.recentSheets.map((sheet, idx) => {
      const stringType = sheet.permalink.split("/").slice(-2)[0].slice(0, -1)
      if (stringType) {
        const type = stringType?.charAt(0).toUpperCase() + stringType?.slice(1);

        const onSelect: any = {
          Sheet: onSheetSelect,
          Report: onReportSelect,
          Dashboard: onDashboardSelect,
        }

        return <SheetItemComponent type={type} data={sheet} key={`${type} _` + idx} onSelect={onSelect[type]} />;
      }
    })
  }

  const Sheets = (): React.ReactNode => {
    console.log("Helo")
    return <Pagination type="Sheet" getData={getPaginatedSheets} onSelect={onSheetSelect} />
  }

  const Reports = (): React.ReactNode => {
    return <Pagination type="Report" getData={getPaginatedReports} onSelect={onReportSelect} />
  }

  const Dashboards = (): React.ReactNode => {
    return <Pagination type="Dashboard" getData={getPaginatedDashboards} onSelect={onDashboardSelect} />
  }

  const onSearchSelect = (type: string, data: IListSheetsItem) => {
    switch (type) {
      case "sheet":
        onSheetSelect(data);
        break;
      case "report":
        onReportSelect(data);
        break;
      case "sight":
        onDashboardSelect(data);
        break;
      default:
        break;
    }
  }

  const renderView = (): React.ReactNode => {
    switch (activeTab) {
      case 0:
        return RecentSheets();
      case 1:
        return Sheets();
      case 2:
        return Reports();
      case 3:
        return Dashboards();
      default:
        return null;
    }
  }

  if (loading) return <Loader />

  return (
    <div id="select-sheet">
      <div className="header">
        <div className="logo-container">
          <img src={SmartSheetLogo} />
          <SignOutButton/>
        </div>
        <div className="title-container">
          <span className="title bold font-style default-dark-font-1">{`Step 1: `}</span>
          <span className="title">Select Item</span>
        </div>
      </div>
      <TabsComponent tabs={["Recents", "Sheets", "Reports", "Dashboards"]} activeTab={activeTab} onChange={setActiveTab} />
      <SearchComponent onSelectCallback={onSearchSelect} />
      <div id="sheet-list">
        {renderView()}
      </div>
    </div >
  );
};
