/*
 * Filename:       SmartSheetsAPI.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */

import axios from "axios";
import { stringify } from "querystring";

import {
  IGetSheetParams,
  IListDashboardParams,
  IListDashboardResponse,
  IListMeetingParticipantsResponse,
  IListRecentsResponse,
  IListReportsPayload,
  IListReportsResponse,
  IListSheetSharesParams,
  IListSheetSharesResponse,
  IListSheetsPayload,
  IListSheetsResponse,
  IResult,
  ISearchParams,
  ISearchResult,
  IShareObject,
  IShareSheetParams,
  IShareSheetPayload,
  IShareSheetResponse,
  ISheet,
  ITokenRequest,
  IUpdateSheetSharePayload,
} from "@models";
import { formatString } from "@helpers";

export class SmartSheetsAPI {
  static routes = {
    authorize: "https://app.smartsheet.com/b/authorize",
    dashboardShares: "https://api.smartsheet.com/2.0/sights/{sightId}/shares",
    dashboardShare: "https://api.smartsheet.com/2.0/sights/{sightId}/shares/{shareId}",
    token: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/RequestToken",
    search: "https://api.smartsheet.com/2.0/search",
    search_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/search", // search a sheet
    share: "https://api.smartsheet.com/2.0/sheets/{sheetId}/shares",
    share_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/share", // share a sheet
    shareReport_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/shareReport", // share a report
    shareDashboard_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/shareDashboard", // share a dashboard
    listshare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/listShare", //get list of share sheets
    listReportShare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/listReportsShare", //get list of report shares
    listDashboardShare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/listDashboardsShare", //get list of dashboard shares
    updateSheetShare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/updateSheetShare",
    updateReportShare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/updateReportShare",
    updateDashboardShare_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/updateDashboardShare",
    sheets: "https://api.smartsheet.com/2.0/sheets",
    sheets_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getRecentSheets",
    reports_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getReports",
    dashboards_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getDashboards",
    sheet: "https://api.smartsheet.com/2.0/sheet/{sheetId}",
    sheet_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getSheet",
    report_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getReport",
    dashboard_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getDashboard",
    sheetShare: "https://api.smartsheet.com/2.0/sheets/{sheetId}/shares/{shareId}",
    recents_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/recents",
    setMeetingParticipants_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/addMeetingParticipant",
    getMeetingParticipants_CustomService: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/getMeetingParticipants",
  };

  /**
   * Request an Access Token.
   * @param {ITokenRequest} requestData The request data
   * @returns {Promise} The access token result.
   */
  static requestToken = async (requestData: ITokenRequest) => {
    console.log("request data", requestData);
    const API_ENDPOINT = SmartSheetsAPI.routes.token;
    const POST_OPTIONS = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payload = {
      client_id: requestData.client_id,
      client_secret: requestData.client_secret,
      code: requestData.code,
      grant_type: requestData.grant_type,
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payload }), POST_OPTIONS);
    console.log("Access token", data);
    return data;
  };

  /**
   * List Sheets.
   * @param {IListSheetsPayload} payload The request data
   * @returns {Promise<IListSheetsResponse>} The list of the sheets.
   */
  static listSheets = async (payload?: IListSheetsPayload): Promise<IListSheetsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.sheets;
    const config = payload
      ? {
          params: {
            payload,
          },
        }
      : {};

    const { data } = await axios.get(API_ENDPOINT, config);

    return data;
  };

  /**
   * List Recent Sheets.
   * @param {string} accessToken Access Token
   * @param {IListSheetsPayload} payload The request data
   * @returns {Promise<IListSheetsResponse>} The list of the sheets.
   */
  static recentSheets = async (accessToken: string, payload?: IListSheetsPayload): Promise<IListRecentsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.recents_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadRecents = {
      accessToken: accessToken,
      payload: stringify({ ...payload }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadRecents }), config);
  
    return data;
  };

  /**
   * List Sheets from custom smartsheet service.
   * @param {string} accessToken The request data
   * @param {IListSheetsPayload} payload The request data
   * @returns {Promise<IListSheetsResponse>} The list of the sheets.
   */
  static getListSheets = async (accessToken: string, payload?: IListSheetsPayload): Promise<IListSheetsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.sheets_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      payload: stringify({ ...payload }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadSheet }), config);

    return data;
  };

  /**
   * List Reports from custom smartsheet service.
   * @param {string} accessToken The request data
   * @param {IListSheetsPayload} payload The request data
   * @returns {Promise<IListSheetsResponse>} The list of the sheets.
   */
  static getListReports = async (accessToken: string, payload?: IListReportsPayload): Promise<IListReportsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.reports_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadReport = {
      accessToken: accessToken,
      payload: stringify({ ...payload }),
    };

    console.log("What is the API endpoint", API_ENDPOINT);

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadReport }), config);

    return data;
  };

  /**
   * List Dashboards from custom smartsheet service.
   * @param {string} accessToken The request data
   * @param {IListDashboardsPayload} payload The request data
   * @returns {Promise<IListDashboardsResponse>} The list of the sheets.
   */
  static getListDashboards = async (accessToken: string, payload?: IListDashboardParams): Promise<any> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.dashboards_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadReport = {
      accessToken: accessToken,
      payload: stringify({ ...payload }),
    };

    console.log("What is the API endpoint", API_ENDPOINT);

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadReport }), config);

    return data;
  };

  /**
   * Get a Sheet.
   * @param {string} accessToken Access Token
   * @param {number} sheetId The request data
   * @param {IGetSheetParams} params The request data
   * @returns {Promise<ISheet>} The list of the sheets.
   */
  static getSheet = async (accessToken: string, sheetId: number, params?: IGetSheetParams): Promise<ISheet> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.sheet_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payload = {
      accessToken,
      sheetId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payload }), config);

    return data;
  };

  /**
   * Get a Report.
   * @param {string} accessToken Access Token
   * @param {number} reportId The request data
   * @param {IGetSheetParams} params The request data
   * @returns {Promise<ISheet>} The list of the sheets.
   */
  static getReport = async (accessToken: string, reportId: number, params?: IGetSheetParams): Promise<ISheet> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.report_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payload = {
      accessToken,
      reportId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payload }), config);

    return data;
  };

  /**
   * Get a Dashboard.
   * @param {string} accessToken Access Token
   * @param {number} sheetId The request data
   * @param {IGetSheetParams} params The request data
   * @returns {Promise<ISheet>} The list of the sheets.
   */
  static getDashboard = async (accessToken: string, dashboardId: number, params?: IGetSheetParams): Promise<ISheet> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.dashboard_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payload = {
      accessToken,
      dashboardId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payload }), config);

    return data;
  };

  /**
   * Delete a Sheet.
   * @param {number} sheetId The request data
   * @returns {Promise<IResult>} The list of the sheets.
   */
  static deleteSheet = async (sheetId: number): Promise<IResult> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.sheet, [sheetId]);

    const { data } = await axios.delete(API_ENDPOINT);

    return data;
  };

  /**
   * List Sheet Shares.
   * @param {number} sheetId The ID of sheet to share.
   * @param {IListSheetSharesParams} params Share Sheet Params.
   * @returns {Promise<IListSheetSharesResponse>} Result containing one or array of share objects.
   */
  static listSheetShares = async (sheetId: number, params?: IListSheetSharesParams): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.share, [sheetId]);
    const config = {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(API_ENDPOINT, config);

    return data;
  };

  /**
   * List Sheet Shares.
   * @param {string} accessToken The request data
   * @param {number} sheetId The ID of sheet to share.
   * @param {IListSheetSharesParams} params Share Sheet Params.
   * @returns {Promise<IListSheetSharesResponse>} Result containing one or array of share objects.
   */
  static getListSheetShares = async (
    accessToken: string,
    sheetId: string | number,
    params?: IListSheetSharesParams
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.listshare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      sheetId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify(payloadSheet), config);

    return data;
  };

  /**
   * List Report Shares.
   * @param {string} accessToken The request data
   * @param {number} reportId The ID of sheet to share.
   * @param {IListSheetSharesParams} params Share Sheet Params.
   * @returns {Promise<IListSheetSharesResponse>} Result containing one or array of share objects.
   */
  static getListReportShares = async (
    accessToken: string,
    reportId: string | number,
    params?: IListSheetSharesParams
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.listReportShare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      reportId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify(payloadSheet), config);

    return data;
  };

  /**
   * List Dashboard Shares.
   * @param {string} accessToken The request data
   * @param {number} dashboardId The ID of sheet to share.
   * @param {IListSheetSharesParams} params Share Sheet Params.
   * @returns {Promise<IListSheetSharesResponse>} Result containing one or array of share objects.
   */
  static getListDashboardShares = async (
    accessToken: string,
    dashboardId: string | number,
    params?: IListSheetSharesParams
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.listDashboardShare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      dashboardId,
      payload: stringify({ ...params }),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify(payloadSheet), config);

    return data;
  };

  /**
   * Update a Sheet Share.
   * @param {number} sheetId The ID of sheet to share.
   * @param {number} shareId The ID of updating sheet share.
   * @param {IUpdateSheetSharePayload} payload A share object or an array of share objects.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static updateSheetShare = async (
    accessToken: string,
    sheetId: string | number,
    shareId: string | number,
    payload?: IUpdateSheetSharePayload
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.updateSheetShare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      sheetId,
      shareId,
      payload: JSON.stringify(payload),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadSheet }), config);

    return data;
  };

  /**
   * Update a Sheet Share.
   * @param {number} reportId The ID of sheet to share.
   * @param {number} shareId The ID of updating sheet share.
   * @param {IUpdateSheetSharePayload} payload A share object or an array of share objects.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static updateReportShare = async (
    accessToken: string,
    reportId: string | number,
    shareId: string | number,
    payload?: IUpdateSheetSharePayload
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.updateReportShare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      reportId,
      shareId,
      payload: JSON.stringify(payload),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadSheet }), config);

    return data;
  };

  /**
   * Update a Sheet Share.
   * @param {number} dashboardId The ID of sheet to share.
   * @param {number} shareId The ID of updating sheet share.
   * @param {IUpdateSheetSharePayload} payload A share object or an array of share objects.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static updateDashboardShare = async (
    accessToken: string,
    dashboardId: string | number,
    shareId: string | number,
    payload?: IUpdateSheetSharePayload
  ): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.updateDashboardShare_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      dashboardId,
      shareId,
      payload: JSON.stringify(payload),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadSheet }), config);

    return data;
  };

  /**
   * Share a Report.
   * @param {number} reportId The ID of report to share.
   * @param {IShareSheetPayload | IShareSheetPayload[]} payload A share object or an array of share objects.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static shareReport = async (
    accessToken: string,
    reportId: number,
    payload: IShareSheetPayload | IShareSheetPayload[]
  ): Promise<IShareSheetResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.shareReport_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadShare = {
      accessToken: accessToken,
      reportId,
      query: "sendEmail=false",
      payload: JSON.stringify(payload),
    };
    // const config = {
    //   params: params,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadShare }), config);

    return data;
  };

  /**
   * Share a Dashboard.
   * @param {number} dashboardId The ID of report to share.
   * @param {IShareSheetPayload | IShareSheetPayload[]} payload A share object or an array of share objects.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static shareDashboard = async (
    accessToken: string,
    dashboardId: number,
    payload: IShareSheetPayload | IShareSheetPayload[]
  ): Promise<IShareSheetResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.shareDashboard_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadShare = {
      accessToken,
      dashboardId,
      query: "sendEmail=false",
      payload: JSON.stringify(payload),
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadShare }), config);

    return data;
  };

  /**
   * Share a Sheet.
   * @param {number} sheetId The ID of sheet to share.
   * @param {IShareSheetPayload | IShareSheetPayload[]} payload A share object or an array of share objects.
   * @param {IShareSheetParams} params Share Sheet Params.
   * @returns {Promise<IShareSheetResponse>} Result containing one or array of share objects.
   */
  static shareSheet = async (
    accessToken: string,
    sheetId: number,
    payload: IShareSheetPayload | IShareSheetPayload[],
    params?: IShareSheetParams
  ): Promise<IShareSheetResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.share_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadShare = {
      accessToken: accessToken,
      sheetId,
      query: "sendEmail=false",
      payload: JSON.stringify(payload),
    };
    // const config = {
    //   params: params,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadShare }), config);

    return data;
  };

  /**
   * Get a Sheet Share.
   * @param {number} sheetId The ID of sheet of the current share.
   * @param {number} shareId The ID of the current sheet share.
   * @returns {Promise<IShareObject>} Share object.
   */
  static getSheetShare = async (sheetId: number, shareId: number): Promise<IShareObject> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.sheetShare, [sheetId, shareId]);

    const { data } = await axios.get(API_ENDPOINT);

    return data;
  };

  /**
   * Delete a Sheet Share.
   * @param {number} sheetId The ID of sheet to share.
   * @param {number} shareId The ID of deleting sheet share.
   * @returns {Promise<IResult>} Result object.
   */
  static deleteSheetShare = async (sheetId: number, shareId: number): Promise<IResult> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.sheetShare, [sheetId, shareId]);

    const { data } = await axios.delete(API_ENDPOINT);

    return data;
  };

  /**
   * Search For everything.
   * @param {ISearchParams} params Search params.
   * @returns {Promise<ISearchResult>} Search Result object.
   */
  static search = async (params: ISearchParams): Promise<ISearchResult> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.search;
    const config = {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(API_ENDPOINT, config);

    return data;
  };

  /**
   * Search For everything  from custom smartsheet service.
   * @param {string} accessToken The request data
   * @param {ISearchParams} params Search params.
   * @returns {Promise<ISearchResult>} Search Result object.
   */
  static getSearch = async (accessToken: string, params: ISearchParams): Promise<ISearchResult> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.search_CustomService;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payloadSheet = {
      accessToken: accessToken,
      payload: stringify({ ...params }),
    };

    console.log(payloadSheet);

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payloadSheet }), config);

    return data;
  };

  /**
   * List Dashboard Shares.
   * @param {number} dashboardId The ID of dashboard to share.
   * @param {IListSheetSharesParams} params Share Sheet Params.
   * @returns {Promise<IListSheetSharesResponse>} Result containing one or array of share objects.
   */
  static listDashboardShares = async (dashboardId: number, params?: IListSheetSharesParams): Promise<IListSheetSharesResponse> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.dashboardShares, [dashboardId]);
    const config = {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(API_ENDPOINT, config);

    return data;
  };

  /**
   * Get a Dashboard Share.
   * @param {number} dashboardId The ID of dashboard of the current share.
   * @param {number} shareId The ID of the current dashboard share.
   * @returns {Promise<IShareObject>} Share object.
   */
  static getDashboardShare = async (dashboardId: number, shareId: number): Promise<IShareObject> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.dashboardShare, [dashboardId, shareId]);

    const { data } = await axios.get(API_ENDPOINT);

    return data;
  };

  /**
   * Delete a Dashboard Share.
   * @param {number} dashboardId The ID of dashboard to share.
   * @param {number} shareId The ID of deleting dashboard share.
   * @returns {Promise<IResult>} Result object.
   */
  static deleteDashboardShare = async (dashboardId: number, shareId: number): Promise<IResult> => {
    const API_ENDPOINT = formatString(SmartSheetsAPI.routes.dashboardShare, [dashboardId, shareId]);

    const { data } = await axios.delete(API_ENDPOINT);

    return data;
  };

  /**
   * List Dashboards.
   * @param {IListDashboardParams} params The request data
   * @returns {Promise<IListDashboardResponse>} The list of the sheets.
   */
  static listDashboards = async (params: IListDashboardParams): Promise<IListDashboardResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.dashboards_CustomService;
    const config = {
      params,
    };

    const { data } = await axios.get(API_ENDPOINT, config);

    return data;
  };

  /**
   * Add email to Meeting Participants list.
   * @param {string} meetingId The request data
   * @param {string} email payload The request data
   * @returns {Promise<IListMeetingParticipantsResponse>} The Meeting Participants list.
   */
  static setMeetingParticipants = async (meetingId: string, email: string): Promise<IListMeetingParticipantsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.setMeetingParticipants_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const payload = {
      meetingId: meetingId,
      email: email,
    };

    const { data } = await axios.post(API_ENDPOINT, stringify({ ...payload }), config);

    return data;
  };

  /**
   * Get Meeting Participants list.
   * @param {string} meetingId The request data
   * @returns {Promise<IListMeetingParticipantsResponse>} The Meeting Participants list.
   */
  static getMeetingParticipants = async (meetingId: string): Promise<IListMeetingParticipantsResponse> => {
    const API_ENDPOINT = SmartSheetsAPI.routes.getMeetingParticipants_CustomService;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${API_ENDPOINT}/${meetingId}`, config);

    return data;
  };
}
