import React from 'react';
import './Loaders.scss';

const size = 80;

export const Loader = () => {
  return (
    <div className="center-spinner">
      <span className="spinner-label">Loading...</span>
      <div className="spinner-loader" />
    </div>
  );
};
