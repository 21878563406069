/*
 * Filename:       useWebex.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */

import { stringify } from "querystring";
import { GrantTypeEnum } from "@models";
import { CiscoWebexEmbededAppAPI } from "@services";
import {
  ICiscoAccessTokenPayload,
  ICiscoListRoomsPayload,
  ICiscoMeetingInProgressPayload,
  ICiscoMeetingParticipantDetailsPayload,
  ICiscoMeetingPayload,
  ICiscoListRoomTabsPayload,
  MeetingStateEnum,
  MeetingTypeEnum,
  ICiscoGetMembershipsPayload,
} from "../models/CiscoWebexEmbeddedApp";

export const useCiscoAPI = () => {
  const {
    REACT_APP_WEBEX_CLIENT_ID: client_id,
    REACT_APP_WEBEX_REDIRECT_URI: redirect_uri,
    REACT_APP_WEBEX_CLIENT_SECRET: client_secret,
  } = process.env;
  const authorizationRequestParams = {
    client_id: client_id,
    response_type: "code",
    redirect_uri: redirect_uri,
    scope:
      "spark-compliance:memberships_read spark:all spark:kms meeting:schedules_read meeting:participants_read meeting:admin_participants_read spark-compliance:meetings_read meeting:participants_write spark-admin:workspaces_read",
    state: "auth",
  };

  const startWebexAuthorization = async () => {
    const authorizeEndpoint = `${CiscoWebexEmbededAppAPI.routes.authorize}?${stringify(authorizationRequestParams)}`;
    console.log(authorizeEndpoint);
    window.location.assign(authorizeEndpoint);
  };

  const getAccessToken = async (code: string) => {
    const tokenRequest: ICiscoAccessTokenPayload = {
      client_id: client_id || "",
      grant_type: GrantTypeEnum.AUTHORIZATION,
      client_secret: client_secret || "",
      code: code, // Code gotten by redirecting from "startWebexAuthorization()"
      redirect_uri: redirect_uri || "",
    };

    const accessToken = await CiscoWebexEmbededAppAPI.accesToken(tokenRequest);
    return accessToken;
  };

  const getAPIRooms = async (code: string) => {
    const roomsRequest: ICiscoListRoomsPayload = {};
    const rooms = await CiscoWebexEmbededAppAPI.listRooms(roomsRequest, code);
    return rooms;
  };

  const getAPIRoomTabs = async (roomId: string, code: string) => {
    const roomTabssRequest: ICiscoListRoomTabsPayload = { roomId };
    const roomTabs = await CiscoWebexEmbededAppAPI.listRoomTabs(roomTabssRequest, code);
    return roomTabs;
  };

  const getAPIMeeting = async (meetingId: string, code: string, current?: boolean, hostEmail?: string) => {
    const getMeetingRequest: ICiscoMeetingPayload = {
      current: current,
      hostEmail: hostEmail,
    };

    const meeting = await CiscoWebexEmbededAppAPI.getMeeting(meetingId, code);
    return meeting;
  };

  const getAPIMemberships = async (roomId: string, code: string) => {
    const getMeetingRequest: ICiscoGetMembershipsPayload = {
      roomId,
    };

    const memberships = await CiscoWebexEmbededAppAPI.getMemberships(getMeetingRequest, code);
    return memberships;
  };

  const getAPIMeetingParticipants = async (meetingId: string, code: string, hostEmail?: string) => {
    const getMeetingRequest: ICiscoMeetingParticipantDetailsPayload = {
      meetingId: meetingId,
      hostEmail: hostEmail,
    };

    const meetingParticipants = await CiscoWebexEmbededAppAPI.getMeetingParticipantDetails(getMeetingRequest, code);
    return meetingParticipants;
  };

  const getAPIParticipants = async (code: string) => {
    const payload: ICiscoMeetingInProgressPayload = {
      meetingType: MeetingTypeEnum.Meeting,
      state: MeetingStateEnum.InProgress,
    };

    const meetings = await CiscoWebexEmbededAppAPI.getMeetingInProgress(code, payload);
    if (meetings && !!meetings.items?.length) {
      const getMeetingRequest: ICiscoMeetingParticipantDetailsPayload = {
        meetingId: meetings.items[0].id,
        hostEmail: meetings.items[0].hostEmail || meetings.items[0].hostDisplayName,
      };

      const meetingParticipants = await CiscoWebexEmbededAppAPI.getMeetingParticipantDetails(getMeetingRequest, code);
      if (meetingParticipants) {
        return meetingParticipants;
      }
    }
    throw Error;
  };

  return {
    startWebexAuthorization,
    getAccessToken,
    getAPIRooms,
    getAPIRoomTabs,
    getAPIMeeting,
    getAPIMeetingParticipants,
    getAPIParticipants,
    getAPIMemberships,
  };
};
