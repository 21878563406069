import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { TableFooter } from "@mui/material";
import { IListReportsItem, IListReportsResponse, IListSheetsItem, IListSheetsResponse } from "@models";
import { Loader } from "@components";
import { SheetItemComponent } from "../SelectSheetComponent/SheetItemComponent/SheetItemComponent";
interface IData {
  onSelect: (data: IListSheetsItem | IListReportsItem) => void,
  getData: (obj: { page: number, pageSize: number }) => Promise<IListSheetsResponse> | Promise<IListReportsResponse>,
  type: "Sheet" | "Report" | "Dashboard",
}

export default function CustomPaginationActionsTable({ onSelect, type, getData }: IData) {
  const [data, setData] = useState<IListSheetsResponse | IListReportsResponse>();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const ROWS_PER_PAGE = 25;
  const TOTAL_PAGES = data ? Math.ceil(data.totalCount / ROWS_PER_PAGE) : 0;
  const SHARABLE_PERMS = ["ADMIN", "EDITOR_SHARE", "OWNER"]

  useEffect(() => {
    const getPaginatedData = async () => {
      setLoading(true);
      const paginatedData = await getData({ page: page, pageSize: ROWS_PER_PAGE });
      const sharableData = paginatedData.data.filter(d => SHARABLE_PERMS.includes(d.accessLevel));
      const nonSharableData = paginatedData.data.filter(d => !SHARABLE_PERMS.includes(d.accessLevel));
      setData({
        ...paginatedData,
        totalCount: paginatedData.totalCount,
        data: [
          ...sharableData, 
          ...nonSharableData,
        ],
      });
      setPage(0);
      setLoading(false);
    };
    getPaginatedData();
  }, [ type ]);

  const getPageData = () => {
    return data?.data.slice((ROWS_PER_PAGE * (page)), (ROWS_PER_PAGE * (page + 1)) + 1) ?? [];
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(1);
  };

  const TablePaginationActions = (props: any) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = async (event: any) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = async (event: any) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Box>
    );
  }

  if (loading) return <Loader />

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableBody>
          {ROWS_PER_PAGE > 0 && getPageData().map((d, idx) => (
            <TableRow key={d.name + idx}>
              <SheetItemComponent type={type} data={d} key={'sheet_' + idx} onSelect={onSelect} />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {TOTAL_PAGES > 0 && (
              <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                colSpan={1}
                count={data?.totalCount ?? 0}
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />)
            }
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
