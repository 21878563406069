/*
 * Filename:       CiscoWebexEmbededApp.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */
import { GrantTypeEnum } from "@models";
export interface ICiscoPagingQueryStringParams {
  max?: number;
}
//Rooms
export enum CiscoRoomTypeEnum {
  DIRECT = "direct",
  GROUP = "group",
}

export interface ICiscoDeleteResponse {
  data: any;
}

export interface ICiscoAccessTokenResponseItem {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_inv: number;
}

export interface ICiscoAccessTokenPayload {
  grant_type: GrantTypeEnum.AUTHORIZATION | GrantTypeEnum.REFERESH;
  client_id: string;
  client_secret: string;
  code: string;
  redirect_uri: string;
}

export interface ICiscoAccessTokenResponse {
  data: ICiscoAccessTokenResponseItem[];
}

export interface ICiscoRoomsResponseItem {
  id: string;
  title: string;
  type: CiscoRoomTypeEnum.DIRECT | CiscoRoomTypeEnum.GROUP;
  isLocked: boolean;
  teamId: string;
  lastActivity: string;
  creatorId: string;
  created: string;
  ownerId: string;
  classificationId: string;
}

export interface ICiscoListRoomsPayload {
  teamId?: string;
  type?: string;
  sortBy?: string;
  max?: number;
}

export interface ICiscoRoomsResponse {
  data: ICiscoRoomsResponseItem[];
}

export interface ICiscoRoomTab {
  id: string;
  roomId: string;
  roomType: "direct" | "group";
  displayName: string;
  contentUrl: string;
  creatorId: string;
  created: string;
}

export interface ICiscoListRoomTabsPayload {
  roomId: string;
}

export interface ICiscoRoomTabsResponse {
  items: ICiscoRoomTab[];
}

export interface ICiscoCreateRoomPayload {
  title: string;
  teamId?: string;
  classificationId?: string;
}

export interface ICiscoRoomIdPayload {
  roomId: string;
}

export interface ICiscoUpdateRoomPayload {
  title: string;
  classificationId?: string;
}

//Teams
export interface ICiscoListTeamsPayload {
  max?: number;
}

export interface ICiscoTeamsResponseItem {
  id: string;
  name: string;
  creatorId: string;
  created: string;
}

export interface ICiscoTeamsResponse {
  data: ICiscoTeamsResponseItem[];
}

export interface ICiscoCreateTeamPayload {
  name: string;
}

export interface ICiscoTeamIdPayload {
  teamId: string;
}

//Reports
export interface ICiscoListReportsPayload {
  reportId?: string;
  service?: string;
  templateId?: number;
  from?: string;
  to?: string;
}

export interface ICiscoReportsResponseItem {
  id: string;
  title: string;
  service: string;
  startDate: string;
  endDate: string;
  siteList: string;
  created: string;
  createdBy: string;
  scheduledFrom: string;
  status: string;
  downloadURL: string;
}

export interface ICiscoReportsResponse {
  data: ICiscoReportsResponseItem[];
}

export interface ICiscoCreateReportPayload {
  templateId: number;
  startDate?: string;
  endDate?: number;
  siteList?: string;
}

export interface ICiscoCreatedReportsResponseItem {
  id: string;
}

export interface ICiscoCreatedReportsResponse {
  data: ICiscoCreatedReportsResponseItem[];
}

export interface ICiscoReportIdPayload {
  reportId: string;
}

//MeetingInvitees

export interface ICiscoGetMeetingInviteesQueryStringParams {
  max?: number;
  hostEmail?: string;
}

export interface ICiscoMeetingInviteesPayload extends ICiscoGetMeetingInviteesQueryStringParams {
  meetingId: string;
}

export interface ICiscoMeetingInviteesResponseItem {
  id: string;
  email: string;
  displayName: string;
  coHost: string;
  meetingId: string;
}

export interface ICiscoMeetingInviteesResponse {
  data: ICiscoMeetingInviteesResponseItem[];
}

export interface ICiscoCreateMeetingInviteesPayload {
  meetingId: string;
  email: string;
  displayName?: string;
  coHost?: boolean;
  hostEmail?: string;
  sendEmail?: boolean;
}

export interface ICiscoMeetingInviteesQueryStringParams {
  hostEmail?: string;
}

export interface ICiscoGetMeetingInviteesPayload extends ICiscoMeetingInviteesQueryStringParams {
  meetingInviteeId: string;
}

export interface ICiscoMeetingInviteesIdPayload {
  meetingInviteeId: string;
}

export interface ICiscoUpdateMeetingInviteesQueryStringParams {
  displayName?: string;
  coHost?: boolean;
  hostEmail?: string;
  sendEmail?: boolean;
}

export interface ICiscoUpdateMeetingInviteesPayload extends ICiscoUpdateMeetingInviteesQueryStringParams {
  email: string;
}

export interface ICiscoDeleteMeetingInviteesQueryStringParams {
  hostEmail?: string;
  sendEmail?: string;
}

export interface ICiscoDeleteMeetingInviteesPayload extends ICiscoDeleteMeetingInviteesQueryStringParams {
  meetingInviteeId: string;
}

export interface ICiscoDeleteMeetingInviteesResponse {
  data: any;
}

//Meetings
export interface ICiscoMeetingSeriesQueryStringParams {
  max?: number;
  from?: string;
  to?: string;
  meetingType?: string;
  state?: string;
  isModified?: boolean;
  hostEmail?: string;
}

export enum MeetingTypeEnum {
  MeetingSeries = "meetingSeries",
  ScheduledMeeting = "scheduledMeeting",
  Meeting = "meeting",
}

export enum MeetingStateEnum {
  Active = "active",
  Scheduled = "scheduled",
  Ready = "ready",
  Lobby = "lobby",
  InProgress = "inProgress",
  Ended = "ended",
  Missed = "missed",
  Expired = "expired",
}

export enum TollTypeEnum {
  Toll = "toll",
  TollFree = "tollFree",
}

export interface IcallInNumbersItem {
  label: string;
  callInNumber: string;
  tollType: TollTypeEnum.Toll | TollTypeEnum.TollFree;
}

export interface IlinksItem {
  rel: string;
  href: string;
  method: string;
}

export interface ItelephonyItem {
  accessCode: string;
  callInNumbers: IcallInNumbersItem[];
  links: IlinksItem[];
}

export interface IregistrationItem {
  autoAcceptRequest: boolean;
  requireFirstName: boolean;
  requireLastName: boolean;
  requireEmail: boolean;
  requireJobTitle: boolean;
  requireCompanyName: boolean;
  requireAddress1: boolean;
  requireAddress2: boolean;
  requireCity: boolean;
  requireState: boolean;
  requireZipCode: boolean;
  requireCountryRegion: boolean;
  requireWorkPhone: boolean;
  requireFax: boolean;
}

export interface ICiscoMeetingSeriesResponseItem {
  id: string;
  meetingSeriesId: string;
  scheduledMeetingId: string;
  meetingNumber: string;
  title: string;
  agenda: string;
  password: string;
  phoneAndVideoSystemPassword: string;
  meetingType: MeetingTypeEnum.MeetingSeries | MeetingTypeEnum.ScheduledMeeting | MeetingTypeEnum.Meeting;
  state:
    | MeetingStateEnum.Active
    | MeetingStateEnum.Scheduled
    | MeetingStateEnum.Ready
    | MeetingStateEnum.Lobby
    | MeetingStateEnum.InProgress
    | MeetingStateEnum.Ended
    | MeetingStateEnum.Missed
    | MeetingStateEnum.Expired;
  isModified: boolean;
  timezone: string;
  start: string;
  end: string;
  hostUserId: string;
  hostDisplayName: string;
  hostEmail: string;
  hostKey: string;
  siteUrl: string;
  webLink: string;
  sipAddress: string;
  dialInIpAddress: string;
  roomId: string;
  enabledAutoRecordMeeting: boolean;
  allowAnyUserToBeCoHost: boolean;
  enabledJoinBeforeHost: boolean;
  enableConnectAudioBeforeHost: boolean;
  joinBeforeHostMinutes: number;
  excludePassword: boolean;
  publicMeeting: boolean;
  reminderTime: number;
  enableAutomaticLock: boolean;
  automaticLockMinutes: number;
  allowFirstUserToBeCoHost: boolean;
  allowAuthenticatedDevices: boolean;
  telephony: ItelephonyItem;
  registration: IregistrationItem;
  integrationTags: string[];
}

export interface ICiscoMeetingSeriesResponse {
  items: ICiscoMeetingSeriesResponseItem[];
}

export interface ICiscoMeetingSeriesPayload extends ICiscoMeetingSeriesQueryStringParams {
  meetingSeriesId: string;
}

export interface ICiscoMeetingInProgressPayload {
  meetingType?: MeetingTypeEnum.MeetingSeries | MeetingTypeEnum.ScheduledMeeting | MeetingTypeEnum.Meeting;
  state?:
    | MeetingStateEnum.Active
    | MeetingStateEnum.Scheduled
    | MeetingStateEnum.Ready
    | MeetingStateEnum.Lobby
    | MeetingStateEnum.InProgress
    | MeetingStateEnum.Ended
    | MeetingStateEnum.Missed
    | MeetingStateEnum.Expired;
}

export interface ICiscoMeetingQueryStringParams {
  current?: boolean;
  hostEmail?: string;
}

export interface ICiscoMeetingPayload {
  current?: boolean;
  hostEmail?: string;
}

export interface ICiscoMeetingResponseItem {
  id: string;
  meetingNumber: string;
  title: string;
  agenda: string;
  password: string;
  phoneAndVideoSystemPassword: string;
  meetingType: MeetingTypeEnum.MeetingSeries | MeetingTypeEnum.ScheduledMeeting | MeetingTypeEnum.Meeting;
  state:
    | MeetingStateEnum.Active
    | MeetingStateEnum.Scheduled
    | MeetingStateEnum.Ready
    | MeetingStateEnum.Lobby
    | MeetingStateEnum.InProgress
    | MeetingStateEnum.Ended
    | MeetingStateEnum.Missed
    | MeetingStateEnum.Expired;
  timezone: string;
  start: string;
  end: string;
  recurrence: string;
  hostUserId: string;
  hostDisplayName: string;
  hostEmail: string;
  hostKey: string;
  siteUrl: string;
  webLink: string;
  sipAddress: string;
  dialInIpAddress: string;
  roomId: string;
  enabledAutoRecordMeeting: boolean;
  allowAnyUserToBeCoHost: boolean;
  enabledJoinBeforeHost: boolean;
  enableConnectAudioBeforeHost: boolean;
  joinBeforeHostMinutes: number;
  excludePassword: boolean;
  publicMeeting: boolean;
  reminderTime: number;
  enableAutomaticLock: boolean;
  automaticLockMinutes: number;
  allowFirstUserToBeCoHost: boolean;
  allowAuthenticatedDevices: boolean;
  telephony: ItelephonyItem;
  registration: IregistrationItem;
  integrationTags: string[];
}

export interface ICiscoMeetingInProgressResponse {
  items: ICiscoMeetingResponseItem[];
}

export interface ICiscoMeetingResponse {
  data: ICiscoMeetingResponseItem;
}

export interface ICiscoListMeetingPayload {
  meetingNumber?: string;
  webLink?: string;
  roomId?: string;
  meetingType?: MeetingTypeEnum;
  state?: MeetingStateEnum;
  participantEmail?: string;
  current?: boolean;
  from?: string;
  to?: string;
  max?: number;
  hostEmail?: string;
  siteUrl?: string;
  integrationTag?: string;
}

//Person Details

export interface ICiscoPersonDetailsQueryStringParams {
  callingData?: boolean;
}

export interface ICiscoPersonDetailsPayload extends ICiscoPersonDetailsQueryStringParams {
  personId: string;
}

export enum PhoneNumbersTypeEnum {
  Work = "work",
  Mobile = "mobile",
  Fax = "fax",
}

export interface IphoneNumbersItem {
  type: PhoneNumbersTypeEnum.Work | PhoneNumbersTypeEnum.Mobile | PhoneNumbersTypeEnum.Fax;
  value: number;
}

export interface IsipAddressesItem {
  type: string;
  value: string;
  primary: boolean;
}

export enum StatusTypeEnum {
  Active = "active",
  Call = "call",
  DoNotDisturb = "DoNotDisturb",
  Inactive = "inactive",
  Meeting = "meeting",
  OutOfOffice = "OutOfOffice",
  Pending = "pending",
  Presenting = "presenting",
  Unknown = "unknown",
}

export enum PersonDetailsInvitePendingEnum {
  True = "true",
  False = "false",
}

export enum PersonDetailsLoginEnabledEnum {
  True = "true",
  False = "false",
}

export enum PersonDetailsTypeEnum {
  Person = "person",
  Bot = "bot",
  Appuser = "appuser",
}

export interface ICiscoPersonDetailsResponseItem {
  id: string;
  emails: string[];
  phoneNumbers: IphoneNumbersItem[];
  extension: string;
  locationId: string;
  displayName: string;
  nickName: string;
  firstName: string;
  lastName: string;
  avatar: string;
  orgId: string;
  roles: string[];
  licenses: string[];
  created: string;
  lastModified: string;
  timezone: string;
  lastActivity: string;
  siteUrls: string[];
  sipAddresses: IsipAddressesItem[];
  status:
    | StatusTypeEnum.Active
    | StatusTypeEnum.Call
    | StatusTypeEnum.DoNotDisturb
    | StatusTypeEnum.Inactive
    | StatusTypeEnum.Meeting
    | StatusTypeEnum.OutOfOffice
    | StatusTypeEnum.Pending
    | StatusTypeEnum.Presenting
    | StatusTypeEnum.Unknown;
  invitePending: PersonDetailsInvitePendingEnum.False | PersonDetailsInvitePendingEnum.True;
  loginEnabled: PersonDetailsLoginEnabledEnum.False | PersonDetailsLoginEnabledEnum.True;
  type: PersonDetailsTypeEnum.Person | PersonDetailsTypeEnum.Bot | PersonDetailsTypeEnum.Appuser;
}

export interface ICiscoPersonDetailsResponse {
  data: ICiscoPersonDetailsResponseItem;
}

//Meeting Participant Details

export interface ICiscoMeetingParticipantDetailsQueryStringParams {
  hostEmail?: string;
}

export interface ICiscoMeetingParticipantDetailsPayload extends ICiscoMeetingParticipantDetailsQueryStringParams {
  meetingId: string;
}

export enum MeetingParticipantDetailsStateEnum {
  Lobby = "lobby",
  Joined = "joined",
}

export enum MeetingParticipantDetailsAudioTypeEnum {
  Pstn = "pstn",
  Voip = "voip",
}

export interface IdevicesItem {
  deviceType: string;
  audioType: MeetingParticipantDetailsAudioTypeEnum.Pstn | MeetingParticipantDetailsAudioTypeEnum.Voip;
  joinedTime: string;
  leftTime: string;
  correlationId: string;
}

export interface ICiscoMeetingParticipantDetailsResponseItem {
  id: string;
  orgId: string;
  host: boolean;
  coHost: boolean;
  spaceModerator: boolean;
  email: string;
  displayName: string;
  invitee: boolean;
  video: string;
  muted: boolean;
  state: MeetingParticipantDetailsStateEnum.Lobby | MeetingParticipantDetailsStateEnum.Joined;
  siteUrl: string;
  meetingId: string;
  hostEmail: string;
  devices: IdevicesItem[];
}

export interface ICiscoMeetingParticipantDetailsResponse {
  items: ICiscoMeetingParticipantDetailsResponseItem[];
}

//Webhooks

export interface ICiscoWebhooksPayload {
  max?: number;
}

export enum WebhookResourceEnum {
  AttachmentActions = "attachmentActions",
  Memberships = "memberships",
  Messages = "messages",
  Rooms = "rooms",
  Meetings = "meetings",
  Recordings = "recordings",
}

export enum WebhookEventEnum {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
  Started = "started",
  Ended = "ended",
  All = "all",
}

export enum WebhookStatusEnum {
  Active = "active",
  Inactive = "inactive",
}

export interface ICiscoWebhooksResponseItem {
  id: string;
  name: string;
  targetUrl: boolean;
  resource:
    | WebhookResourceEnum.AttachmentActions
    | WebhookResourceEnum.Memberships
    | WebhookResourceEnum.Messages
    | WebhookResourceEnum.Rooms
    | WebhookResourceEnum.Meetings
    | WebhookResourceEnum.Recordings;
  event:
    | WebhookEventEnum.Created
    | WebhookEventEnum.Updated
    | WebhookEventEnum.Deleted
    | WebhookEventEnum.Started
    | WebhookEventEnum.Ended
    | WebhookEventEnum.All;
  filter: string;
  secret: string;
  status: WebhookStatusEnum.Active | WebhookStatusEnum.Inactive;
  created: string;
  ownedBy: string;
}

export interface ICiscoWebhooksResponse {
  items: ICiscoWebhooksResponseItem[];
}

export interface ICiscoCreateWebhooksPayload {
  name: string;
  targetUrl: string;
  resource:
    | WebhookResourceEnum.AttachmentActions
    | WebhookResourceEnum.Memberships
    | WebhookResourceEnum.Messages
    | WebhookResourceEnum.Rooms
    | WebhookResourceEnum.Meetings
    | WebhookResourceEnum.Recordings;
  event:
    | WebhookEventEnum.Created
    | WebhookEventEnum.Updated
    | WebhookEventEnum.Deleted
    | WebhookEventEnum.Started
    | WebhookEventEnum.Ended
    | WebhookEventEnum.All;
  filter?: string;
  secret?: string;
  ownedBy?: string;
}

export interface ICiscoCreateWebhooksResponse {
  items: ICiscoWebhooksResponseItem;
}

export interface ICiscoWebhooksIdPayload {
  webhookId: string;
}

export interface ICiscoGetMembershipsPayload {
  roomId?: string;
  personId?: string;
  personEmail?: string;
  max?: number;
}

export interface IMembership {
  id: string;
  roomId: string;
  personId: string;
  personEmail: string;
  personDisplayName: string;
  personOrgId: string;
  isModerator: boolean;
  isRoomHidden: boolean;
  roomType: "direct" | "group";
  isMonitor: boolean;
  created: string;
}

export interface ICiscoGetMembershipsResponse {
  items: IMembership[];
}
