import React, { useEffect, useState } from 'react';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

interface IRadioPickerOptions {
  label: string;
  value: string | number;
  name: string;
}

interface IRadioPickerProps {
  name: string;
  defaultValue: string;
  options: IRadioPickerOptions[];
  callBack: Function;
}

export const RadioPicker: React.FC<IRadioPickerProps> = ({ name, defaultValue, options, callBack }) => {
  // handle the callback selection
  const handleOnSelection = (value: any) => {
    callBack(value);
  };

  return (
    <div className="option-picker-wrapper">
      <FormControl component="fieldset">
        <RadioGroup name={name} onChange={(e) => handleOnSelection(e.target.value)}>
          {options.map(({ label, value, name }, idx) => {
            return <FormControlLabel label={label} key={idx} value={value} control={<Radio />} />;
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
