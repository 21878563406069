/*
 * Filename:       useSmartSheetsAPI.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */
import { stringify } from "querystring";
import {
  AccessLevelEnum,
  GrantTypeEnum,
  IListDashboardParams,
  IListSheetSharesParams,
  IListSheetsItem,
  IListSheetsPayload,
  ISearchParams,
  IShareSheetPayload,
  ITokenRequest,
  IUpdateSheetSharePayload,
} from "@models";
import { SmartSheetsAPI } from "@services";

const authorizationRequestParams = {
  response_type: "code",
  client_id: process.env.REACT_APP_SMARTSHEET_CLIENT_ID,
  scope: "READ_SHEETS WRITE_SHEETS READ_SIGHTS SHARE_SHEETS SHARE_SIGHTS",
  state: "auth",
};

interface PaginatedParams {
  page: number;
  pageSize: number;
}

export const useSmartSheetsAPI = () => {
  const getAllSheets = async () => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetsPayload = {
      page: 1,
      pageSize: 20,
      includeAll: true,
    };

    console.log(accessToken, request);

    const paginatedData = await SmartSheetsAPI.getListSheets(accessToken, request);
    return paginatedData;
  };

  const getAllSheetShares = async (sheetId: string | number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetSharesParams = {
      page: 1,
      pageSize: 20,
      includeAll: true,
    };

    const data = await SmartSheetsAPI.getListSheetShares(accessToken, sheetId, request);
    return data;
  };

  const getAllReportShares = async (reportId: string | number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetSharesParams = {
      page: 1,
      pageSize: 20,
      includeAll: true,
    };

    const data = await SmartSheetsAPI.getListReportShares(accessToken, reportId, request);
    return data;
  };

  const getAllDashboardShares = async (dashboardId: string | number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetSharesParams = {
      page: 1,
      pageSize: 20,
      includeAll: true,
    };

    const data = await SmartSheetsAPI.getListDashboardShares(accessToken, dashboardId, request);
    return data;
  };

  const updateSheetShare = async (sheetId: string | number, shareId: string | number, accessLevel: string) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IUpdateSheetSharePayload = {
      accessLevel,
    };

    const data = await SmartSheetsAPI.updateSheetShare(accessToken, sheetId, shareId, request);
    return data;
  };

  const updateReportShare = async (reportId: string | number, shareId: string | number, accessLevel: string) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IUpdateSheetSharePayload = {
      accessLevel,
    };

    const data = await SmartSheetsAPI.updateReportShare(accessToken, reportId, shareId, request);
    return data;
  };

  const updateDashboardShare = async (dashboardId: string | number, shareId: string | number, accessLevel: string) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IUpdateSheetSharePayload = {
      accessLevel,
    };

    const data = await SmartSheetsAPI.updateDashboardShare(accessToken, dashboardId, shareId, request);
    return data;
  };

  const getSheet = async (sheetId: number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const sheetData = await SmartSheetsAPI.getSheet(accessToken, sheetId);
    return sheetData;
  };

  const getReport = async (reportId: number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const reportData = await SmartSheetsAPI.getReport(accessToken, reportId);
    return reportData;
  };

  const getDashboard = async (dashboardId: number) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const dashboardData = await SmartSheetsAPI.getDashboard(accessToken, dashboardId);
    return dashboardData;
  };

  const getPaginatedDashboards = async ({ page, pageSize }: PaginatedParams) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListDashboardParams = {
      page,
      pageSize,
      includeAll: true,
    };

    const paginatedData = await SmartSheetsAPI.getListDashboards(accessToken, request);

    return paginatedData;
  };

  const getPaginatedSheets = async ({ page, pageSize }: PaginatedParams) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetsPayload = {
      page,
      pageSize,
      includeAll: true,
    };

    

    const paginatedData = await SmartSheetsAPI.getListSheets(accessToken, request);
    return paginatedData;
  };

  const getPaginatedReports = async ({ page, pageSize }: PaginatedParams) => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const request: IListSheetsPayload = {
      page,
      pageSize,
      includeAll: true,
    };

    const paginatedData = await SmartSheetsAPI.getListReports(accessToken, request);
    return paginatedData;
  };

  const getRecentSheets = async () => {
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const recentsSheetData = localStorage.getItem("smartsheet_recent_sheets");

    if (recentsSheetData) {
      return JSON.parse(recentsSheetData);
    }

    const request: IListSheetsPayload = {
      page: 1,
      pageSize: 20,
      includeAll: true,
    };

    try {
      const paginatedData = await SmartSheetsAPI.recentSheets(accessToken, request);

      console.log("Recent Sheets [In API Call] ===============", paginatedData);

      const test = await Promise.all(
        paginatedData.data.map(async (item) => {
          console.log("recents item:", item);
          const { type } = item;

          let data;
          if (type === "SHEET") data = await SmartSheetsAPI.getSheet(accessToken, item.id, { page: 1, pageSize: 1 });
          if (type === "REPORT") data = await SmartSheetsAPI.getReport(accessToken, item.id, { page: 1, pageSize: 1 });
          if (type === "DASHBOARD") data = await SmartSheetsAPI.getDashboard(accessToken, item.id, { page: 1, pageSize: 1 });

          if (data) {
            return {
              id: data.id,
              accessLevel: data.accessLevel,
              createdAt: data.createdAt,
              modifiedAt: data.modifiedAt,
              name: data.name,
              permalink: data.permalink,
            };
          }
        })
      );

      console.log("Recent Sheets [Post Api Calls]", await test);

      if (test) {
        localStorage.setItem("smartsheet_recent_sheets", JSON.stringify(test));
      }

      return test;
    } catch (e) {
      console.log("Recent Sheets [API Error] ===============", e);
      return [];
    }
  };

  const getToken = async (code: string) => {
    const tokenRequest: ITokenRequest = {
      client_id: process.env.REACT_APP_SMARTSHEET_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_SMARTSHEET_CLIENT_SECRET || "",
      code,
      grant_type: GrantTypeEnum.AUTHORIZATION,
    };

    const token = await SmartSheetsAPI.requestToken(tokenRequest);
    return token;
  };

  const search = async (searchString: string) => {
    const searchRequest: ISearchParams = {
      query: searchString,
      scopes: "reportNames,sightNames,sheetNames",
    };
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";
    const searchResults = await SmartSheetsAPI.getSearch(accessToken, searchRequest);

    return searchResults;
  };

  const shareDashboard = async (dashboardId: number, userEmails: string[], accessLevel: AccessLevelEnum) => {
    const payload: IShareSheetPayload[] = userEmails.map((email): IShareSheetPayload => {
      return {
        accessLevel,
        email,
      };
    });
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";

    const data = await SmartSheetsAPI.shareDashboard(accessToken, dashboardId, payload);

    return data;
  };

  const shareSheet = async (sheetId: number, userEmails: string[], accessLevel: AccessLevelEnum) => {
    const payload: IShareSheetPayload[] = userEmails.map((email): IShareSheetPayload => {
      return {
        accessLevel,
        email,
      };
    });
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";

    const data = await SmartSheetsAPI.shareSheet(accessToken, sheetId, payload);

    return data;
  };

  const shareReport = async (reportId: number, userEmails: string[], accessLevel: AccessLevelEnum) => {
    console.log("Sharing Report!!!!!");
    const payload: IShareSheetPayload[] = userEmails.map((email): IShareSheetPayload => {
      return {
        accessLevel,
        email,
      };
    });
    const accessToken = localStorage.getItem("smartsheet_access_token") || "";

    const data = await SmartSheetsAPI.shareReport(accessToken, reportId, payload);

    return data;
  };

  const startAuthorization = async () => {
    const authorizeEndpoint = `${SmartSheetsAPI.routes.authorize}?${stringify(authorizationRequestParams)}`;

    window.location.assign(authorizeEndpoint);
  };

  const setMeetingParticipants = async (meetingId: string, email: string) => {
    const data = await SmartSheetsAPI.setMeetingParticipants(meetingId, email);

    return data;
  };

  return {
    getPaginatedDashboards,
    getRecentSheets,
    getAllSheets,
    getAllSheetShares,
    getAllReportShares,
    getAllDashboardShares,
    getPaginatedReports,
    getPaginatedSheets,
    getSheet,
    getReport,
    getDashboard,
    getToken,
    search,
    shareDashboard,
    shareSheet,
    shareReport,
    startAuthorization,
    setMeetingParticipants,
    updateSheetShare,
    updateReportShare,
    updateDashboardShare,
  };
};
