import React, { FC } from "react";
import "./SignOutButton.scss";
import { useHistory } from "react-router";

const SignOutButton: FC = () => {
  const history = useHistory();

  const onSignOut = () => {
    const cookies = document.cookie.replaceAll(" ", "").split(";");
    console.log(cookies);
    localStorage.removeItem("smartsheet_access_token");
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      console.log(name);
      document.cookie = name + "=;domain=.smartsheet.com;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    console.log(document.cookie);
    history.push("/");
  }

  return (
    <button className="SignOut-Btn" onClick={onSignOut}>
      Sign out
    </button>
  );
}

export { SignOutButton };