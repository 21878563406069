import React from 'react';

import './TabsComponent.scss';

interface Props {
  tabs?: string[] | null;
  activeTab?: number;
  onChange?: Function;
}

const _TabsComponent: React.FC<Props> = ({ tabs = null, activeTab = 0, onChange = () => null }) => {
  return (
    <div className="tab-container">
      {tabs?.map((tab, idx) => {
        return (
          <div key={idx} className={'tab-item' + (idx === activeTab ? ' active' : '')} onClick={() => onChange(idx)}>
            {tab}
          </div>
        );
      })}
    </div>
  );
};

export const TabsComponent = React.memo(_TabsComponent, (prev, next) => prev.activeTab === next.activeTab);