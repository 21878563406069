require('dotenv').config();

import React from 'react';
import ReactDOM from 'react-dom';
// import { Router, useHistory } from 'react-router';
import { AppProvider } from '@contexts';

import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';

const webex: any = window;

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <Router>
        <App webex={webex} />
      </Router>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
