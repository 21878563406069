import axios from "axios";

export class DataDogAPI {
  static routes = {
    submitMetric: process.env.REACT_APP_SMARTSHEET_SERVICE_URI + "api/v1/submitMetric"
  }

  static async submitMetric(payload: any) {
    const API_ENDPOINT = this.routes.submitMetric;
    const POST_OPTIONS = {
      headers: {
        "Content-Type": "application/json",
        "DD-API-KEY": process.env.REACT_APP_DATADOG_API_KEY,
      },
    };

    try {
      const { data } = await axios.post(API_ENDPOINT, { payload, options: POST_OPTIONS});
      console.log("Datadog Response====", data);
      return data;
    } catch (e) {
      console.error("Datadog error",e);
    }
  }
}