import React from "react";
import { useHistory, useLocation, Switch, Route } from "react-router-dom";

import { AppContext } from "@contexts";
import {
  SelectSheetPermissions,
  SelectSheetComponent,
  PreviewPage,
  Loader,
  ShareComponent,
  SheetPermissionError,
} from "@components";
import PropTypes from "prop-types";

import "./App.scss";
import { useCiscoAPI, useLogin, useSmartSheetsAPI, useDataDog } from "@hooks";

type webex = {
  webex: any;
};

const App = ({ webex }: webex) => {
  const { REACT_APP_SMARTSHEET_CLIENT_ID: clientId, REACT_APP_SMARTSHEET_CLIENT_SECRET: secretKey } = process.env;

  const context = React.useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { getPaginatedSheets } = useSmartSheetsAPI();
  const { getAPIRooms, startWebexAuthorization } = useCiscoAPI();
  const { authSmartsheet, validateSmartsheet, authCisco, validateCisco } = useLogin();
  const { submitUserLoginMetric } = useDataDog();

  React.useEffect(() => {
    if (context.appShareSession && context.activeSheet) {
      history.push("/preview");
    } else {
      context.setActiveSheet(undefined);
    }
  }, [context.appShareSession]);

  React.useEffect(() => {
    if (!context.activeSheet && !context.appShareSession && context.appAuthorized) {
      history.push("/sheet");
    }
  }, [context.activeSheet]);

  const goToLogin = () => {
    window.location.href = `https://app.smartsheet.com/b/authorize?response_type=code&client_id=${clientId}&scope=READ_SHEETS WRITE_SHEETS SHARE_SHEETS READ_SIGHTS SHARE_SIGHTS`;
  };

  React.useEffect(() => {
    console.log("check auth");

    const hasSmartsheetSession = () => {
      const cookies = document.cookie.replaceAll(" ", "").split(";");
      const session = ["__S3S_F", "S3S_F"];
      for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        console.log(session.indexOf(name), name);
        if (session.indexOf(name) != -1){
          session.splice(session.indexOf(name), 1);
        }
      }

      return session.length === 0;
    }

// This is code written a while back but never made it to Staging or Production. The engineer is no longer at Smartsheet
// so this is commented out for now.
//
//     const persistSmartsheetSession = () => {
//       const smartsheetCookies = ["__S3S_F", "S3S_F"];
//       const cookies = document.cookie.replaceAll(" ", "").split(";");
//       for (const cookie of cookies) {
//         const eqPos = cookie.indexOf("=");
//         const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
//
//         if (smartsheetCookies.includes(name)) {
//           console.log(name);
//           const expDate = new Date();
//           expDate.setDate(expDate.getDate()+ 7);
//           document.cookie = name + `=;domain=.smartsheet.com;expires=${expDate.toUTCString()}`;
//         }
//       }
//
//       console.log(document.cookie);
//     }

    const handleAuth = async () => {
      const smartsheetAccessToken = localStorage.getItem("smartsheet_access_token");
      const ciscoAccessToken = localStorage.getItem("cisco_access_token");
      const authCode = new URLSearchParams(window.location.search).get("code");

      if (authCode) {
        localStorage.setItem("auth_code", authCode);
      }

      if (!window.location.href.includes("localhost") && !hasSmartsheetSession() && location.pathname != "/smartsheet") {
        localStorage.removeItem("smartsheet_access_token");
      }

      switch (location.pathname) {
        case "/":

          if (!smartsheetAccessToken || smartsheetAccessToken && !(await validateSmartsheet())) {
            goToLogin();
          }

          if (!ciscoAccessToken || ciscoAccessToken && !(await validateCisco(ciscoAccessToken))) {
            startWebexAuthorization();
          }
//
//           persistSmartsheetSession();

          history.push("/sheet");

          break;
        case "/smartsheet":

          if (authCode) {
            localStorage.removeItem("smartsheet_access_token");
            localStorage.removeItem("smartsheet_recent_sheets");
            await authSmartsheet(authCode);
            context.setAppAuthorized(true);
          }

          await submitUserLoginMetric();
          history.push("/");

          break;
        case "/cisco":
          if (authCode) {
            localStorage.removeItem("cisco_access_token");
            await authCisco(authCode);
          }

          history.push("/");

          break;
      }
    }

    handleAuth();
  }, [location]);

  return (
    <div id="app-wrapper">
      <div id="app-content">
        <Switch>
          <Route exact path="/sheet" component={SelectSheetComponent} />
          <Route exact path="/permission-error" component={SheetPermissionError} />
          <Route exact path="/permissions" component={SelectSheetPermissions} />
          <Route exact path="/preview" component={PreviewPage} />
          <Route exact path="/share" component={ShareComponent} />
          <Route exact path="/" component={Loader} />
        </Switch>
      </div>
    </div>
  );
};

App.propTypes = {
  webex: PropTypes.any.isRequired,
};

export default App;
