import { IListSheetsItem } from "@models";

export const updateRecentSheets = (sheetData: IListSheetsItem) => {
  const SMARTSHEET_RECENT_SHEETS = 'smartsheet_recent_sheets';
  const recentsSheetData = localStorage.getItem(SMARTSHEET_RECENT_SHEETS);

  if (recentsSheetData) {
    const parsedRecentsSheetData = JSON.parse(recentsSheetData);
    const isInCache = parsedRecentsSheetData.filter((sheet: IListSheetsItem) => {
      return sheet.id === sheetData.id;
    })

    let finalSheetsData;

    if (isInCache.length >= 1) {
      const oldSheetsWithoutNewSheet = parsedRecentsSheetData.filter((sheet: IListSheetsItem) => sheet.id !== sheetData.id);
      finalSheetsData = [sheetData, ...oldSheetsWithoutNewSheet];
    } else {
      finalSheetsData = [sheetData, ...parsedRecentsSheetData];
    }

    localStorage.removeItem(SMARTSHEET_RECENT_SHEETS);
    localStorage.setItem(SMARTSHEET_RECENT_SHEETS, JSON.stringify(finalSheetsData));
  }
}