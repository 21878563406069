import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSmartSheetsAPI } from "@hooks";
import { ISearchResult, ISearchResultItem, ISheet } from "@models";
import { 
  SearchIcon, 
  SheetSearchIcon, 
  ReportSearchIcon, 
  DashboardSearchIcon 
} from "@assets";
import "./SearchComponent.scss";

import debounce from "lodash.debounce";

interface ISearchComponent {
  onSelectCallback: Function;
}

export const SearchComponent: React.FC<ISearchComponent> = ({ onSelectCallback }) => {
  const [searchResult, setSearchResult] = useState<ISearchResultItem[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const { getSheet, getReport, getDashboard, search } = useSmartSheetsAPI();

  const onSelect = async (item: ISearchResultItem) => {
    console.log("select", item.objectType);
    
    let data;
    switch (item.objectType) {
      case "sheet":
        data = await getSheet(item.objectId);
        break;
      case "report":
        data = await getReport(item.objectId);
        break;
      case "sight":
        data = await getDashboard(item.objectId);
        break;
      default:
        break;
    }

    console.log(item.objectType, data);

    if (data?.id) {
      onSelectCallback(
        item.objectType,
        {
          id: data.id,
          accessLevel: data.accessLevel,
          createdAt: data.createdAt,
          modifiedAt: data.modifiedAt,
          name: data.name,
          permalink: data.permalink,
        }
      );
    }
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value
    if (searchString) {
      search(searchString).then((response: ISearchResult) => {
        setSearchResult(response?.results);
        setShowResults(true);
      });
    } else {
      setSearchResult([]);
      setShowResults(false);
    }
  }

  const debouncedOnChange = useMemo(() => debounce(onSearchChange, 300), [])
    
  return (
    <div className="search-container">
      <input
        type="search"
        className={`search-input ${showResults && "results-input"}`}
        placeholder="Search"
        onChange={debouncedOnChange}
      />

      <img src={SearchIcon} className="search-input-icon" />

      {showResults && 
        <div className="search-results">
          {searchResult?.map((item, idx) => {
            const { objectType } = item;
            return (
              <div className="search-result-item" key={"search-result-" + idx} onClick={() => onSelect(item)}>
                {objectType === "sheet" && <img src={SheetSearchIcon} />}
                {objectType === "report" && <img src={ReportSearchIcon} />}
                {objectType === "sight" && <img src={DashboardSearchIcon} />}
                {item.text}
              </div>
            );
          })}
          {searchResult.length === 0 && 
            <div className="search-result-item no-results">
              No results
            </div>
          }
        </div>
      }
    </div>
  );
};
