/*
 * Filename:       app.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */
import React, { useEffect, createContext, useState, ReactChild, ReactChildren, Dispatch, SetStateAction } from "react";
import { IListSheetsItem, ICiscoAccessTokenResponseItem, ICiscoMeetingParticipantDetailsResponseItem, AccessLevelEnum, IListReportsItem, IListDashboardsItem } from "@models";
import { useHistory } from "react-router-dom";

type theme = "light" | "dark";

export type typeApplicationContext = {
  appAuthorized: boolean;
  setAppAuthorized: (status: boolean) => void;
  theme: theme;
  setTheme: (theme: theme) => void;
  appShareSession: boolean;
  setAppShareSession: (control: boolean) => void;
  meetingInfo: any;
  setMeetingInfo: (meetingInfo: any) => void;
  webex: any;
  setWebex: (webex: any) => void;
  activeSheet: IListSheetsItem | undefined;
  setActiveSheet: (sheet: IListSheetsItem | undefined) => void;
  activeReport: IListReportsItem | undefined;
  setActiveReport: (report: IListReportsItem | undefined) => void;
  activeDashboard: IListReportsItem | undefined;
  setActiveDashboard: (report: IListReportsItem | undefined) => void;
  allSheets: IListSheetsItem[];
  setAllSheets: (sheets: IListSheetsItem[]) => void;
  recentSheets: IListSheetsItem[];
  setRecentSheets: (sheets: IListSheetsItem[]) => void;
  ciscoToken: string | undefined;
  setCiscoToken: (ciscoToken: string) => void;
  meetingInitiator: ICiscoMeetingParticipantDetailsResponseItem | undefined;
  setMeetingInitiator: (initiator: ICiscoMeetingParticipantDetailsResponseItem) => void;
  meetingUsers: ICiscoMeetingParticipantDetailsResponseItem[];
  setMeetingUsers: (meetingParticipants: ICiscoMeetingParticipantDetailsResponseItem[]) => void;
  sharingURL: string;
  setSharingURL: (shareURL: string) => void;
  roomUsers: string[];
  setRoomUsers: (emails: string[]) => void;
};

export const AppContext = createContext<typeApplicationContext>({
  appAuthorized: false,
  setAppAuthorized: () => { },
  theme: "light",
  setTheme: () => { },
  appShareSession: false,
  setAppShareSession: () => { },
  meetingInfo: undefined,
  setMeetingInfo: () => { },
  webex: window,
  setWebex: () => { },
  activeSheet: undefined,
  setActiveSheet: () => { },
  activeReport: undefined,
  setActiveReport: () => { },
  activeDashboard: undefined,
  setActiveDashboard: () => { },
  allSheets: [],
  setAllSheets: () => { },
  recentSheets: [],
  setRecentSheets: () => { },
  ciscoToken: undefined,
  setCiscoToken: () => { },
  meetingInitiator: undefined,
  setMeetingInitiator: () => { },
  meetingUsers: [],
  setMeetingUsers: () => { },
  sharingURL: "",
  setSharingURL: () => { },
  roomUsers: [],
  setRoomUsers: () => { },
});

// This is the app context provider is passed to any component requiring the context
export const AppProvider: React.FC = ({ children }) => {
  const [appAuthorized, setAppAuthorized] = useState<boolean>(false);
  const [theme, setTheme] = useState<theme>("light");
  const [meetingInfo, setMeetingInfo] = useState<any>();
  const [webex, setWebex] = useState<any>(window);
  const [appShareSession, setAppShareSession] = useState<boolean>(false);
  const [recentSheets, setRecentSheets] = useState<IListSheetsItem[]>([]);
  const [allSheets, setAllSheets] = useState<IListSheetsItem[]>([]);
  const [activeSheet, setActiveSheet] = useState<IListSheetsItem | undefined>();
  const [activeReport, setActiveReport] = useState<IListReportsItem | undefined>();
  const [activeDashboard, setActiveDashboard] = useState<IListDashboardsItem | undefined>();
  const [ciscoToken, setCiscoToken] = useState<string | undefined>();
  const [meetingUsers, setMeetingUsers] = useState<ICiscoMeetingParticipantDetailsResponseItem[]>([]);
  const [meetingInitiator, setMeetingInitiator] = useState<ICiscoMeetingParticipantDetailsResponseItem | undefined>();
  const [sharingURL, setSharingURL] = useState<string>("");
  const [roomUsers, setRoomUsers] = useState<string[]>([]);

  const history = useHistory();

  return (
    <AppContext.Provider
      value={{
        appAuthorized,
        setAppAuthorized,
        theme,
        setTheme,
        meetingInfo,
        setMeetingInfo,
        appShareSession,
        setAppShareSession,
        webex,
        setWebex,
        activeSheet,
        activeReport,
        activeDashboard,
        recentSheets,
        setRecentSheets,
        setActiveSheet,
        setActiveReport,
        setActiveDashboard,
        allSheets,
        setAllSheets,
        ciscoToken,
        setCiscoToken,
        meetingUsers,
        setMeetingUsers,
        meetingInitiator,
        setMeetingInitiator,
        sharingURL,
        setSharingURL,
        roomUsers,
        setRoomUsers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
