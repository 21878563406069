/*
 * Filename:       CiscoWebexEmbededAppAPI.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */
import axios from "axios";
import request from "axios";
import { stringify } from "querystring";

import {
  ICiscoAccessTokenPayload,
  ICiscoAccessTokenResponseItem,
  ICiscoAccessTokenResponse,
  ICiscoCreatedReportsResponse,
  ICiscoCreateMeetingInviteesPayload,
  ICiscoCreateReportPayload,
  ICiscoCreateRoomPayload,
  ICiscoCreateTeamPayload,
  ICiscoDeleteMeetingInviteesPayload,
  ICiscoDeleteMeetingInviteesResponse,
  ICiscoDeleteResponse,
  ICiscoGetMeetingInviteesPayload,
  ICiscoListReportsPayload,
  ICiscoListRoomsPayload,
  ICiscoListTeamsPayload,
  ICiscoMeetingInviteesIdPayload,
  ICiscoMeetingInviteesPayload,
  ICiscoMeetingInviteesResponse,
  ICiscoReportIdPayload,
  ICiscoReportsResponse,
  ICiscoRoomIdPayload,
  ICiscoRoomsResponse,
  ICiscoTeamIdPayload,
  ICiscoTeamsResponse,
  ICiscoUpdateMeetingInviteesPayload,
  ICiscoUpdateRoomPayload,
  ICiscoMeetingSeriesPayload,
  ICiscoMeetingSeriesResponse,
  ICiscoMeetingPayload,
  ICiscoMeetingResponseItem,
  ICiscoListMeetingPayload,
  ICiscoPersonDetailsPayload,
  ICiscoPersonDetailsResponse,
  ICiscoMeetingParticipantDetailsPayload,
  ICiscoMeetingParticipantDetailsResponse,
  ICiscoWebhooksPayload,
  ICiscoWebhooksResponse,
  ICiscoCreateWebhooksPayload,
  ICiscoWebhooksIdPayload,
  ICiscoCreateWebhooksResponse,
  ICiscoMeetingParticipantDetailsResponseItem,
  MeetingParticipantDetailsStateEnum,
  ICiscoMeetingInProgressPayload,
  ICiscoMeetingInProgressResponse,
  ICiscoRoomTabsResponse,
  ICiscoListRoomTabsPayload,
  ICiscoGetMembershipsPayload,
  ICiscoGetMembershipsResponse,
} from "../models/CiscoWebexEmbeddedApp";

export class CiscoWebexEmbededAppAPI {
  static routes = {
    authorize: "https://webexapis.com/v1/authorize",
    accessToken: "https://webexapis.com/v1/access_token",
    rooms: "https://webexapis.com/v1/rooms",
    roomTabs: "https://webexapis.com/v1/room/tabs",
    teams: "https://webexapis.com/v1/teams",
    reports: "https://webexapis.com/v1/reports",
    meetingInvitees: "https://webexapis.com/v1/meetingInvitees",
    meetings: "https://webexapis.com/v1/meetings",
    people: "https://webexapis.com/v1/people",
    meetingParticipants: "https://webexapis.com/v1/meetingParticipants",
    webhooks: "https://webexapis.com/v1/webhooks",
    memberships: "https://webexapis.com/v1/memberships",
  };

  /**
   * Getting an Access Token.
   * @param {ICiscoAccessTokenPayload} payload The request data
   * @returns {Promise<ICiscoAccessTokenResponseItem>} Webex access token.
   */
  static accesToken = async (payload: ICiscoAccessTokenPayload): Promise<ICiscoAccessTokenResponseItem> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.accessToken;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, stringify({ ...payload }), config);
    return data;
  };

  /**
   * List Rooms.
   * @param {ICiscoListRoomsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomsResponse>} List Rooms.
   */
  static listRooms = async (payload: ICiscoListRoomsPayload, accessToken: string): Promise<ICiscoRoomsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Rooms: ", data);
    return data;
  };

  /**
   * List Room Tabs.
   * @param {ICiscoListRoomTabsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomTabsResponse>} List Rooms.
   */
  static listRoomTabs = async (payload: ICiscoListRoomTabsPayload, accessToken: string): Promise<ICiscoRoomTabsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.roomTabs;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}?${stringify({ ...payload })}`, config);
    console.log("List Rooms Tabs: ", data);
    return data;
  };

  /**
   * Create a Room.
   * @param {ICiscoCreateRoomPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomsResponse>} Creates a room..
   */
  static CreateRoom = async (payload: ICiscoCreateRoomPayload, accessToken: string): Promise<ICiscoRoomsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, payload, config);
    console.log("Create a Room: ", data);
    return data;
  };

  /**
   * Get Room Details.
   * @param {ICiscoRoomIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomsResponse>} Shows details for a room, by ID.
   */
  static getRoomDetails = async (payload: ICiscoRoomIdPayload, accessToken: string): Promise<ICiscoRoomsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Get Room Details: ", data);
    return data;
  };

  /**
   * Get Room Details.
   * @param {ICiscoGetMembershipsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoGetMembershipsResponse>} Shows details for a room, by ID.
   */
  static getMemberships = async (payload: ICiscoGetMembershipsPayload, accessToken: string): Promise<ICiscoGetMembershipsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.memberships;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}?${stringify({ ...payload })}`, config);
    console.log("Get Memberships: ", data);
    return data;
  };

  /**
   * Get Room Meeting Details.
   * @param {ICiscoRoomIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomsResponse>} Shows Webex meeting details for a room such as the SIP address, meeting URL, toll-free and toll dial-in numbers.
   */
  static getRoomMeetingDetails = async (payload: ICiscoRoomIdPayload, accessToken: string): Promise<ICiscoRoomsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}/${stringify({ ...payload })}/meetingInfo`, config);
    console.log("Get Room Meeting Details: ", data);
    return data;
  };

  /**
   * Update a Room.
   * @param {ICiscoRoomIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @param {ICiscoRoomIdPayload} body The request data
   * @returns {Promise<ICiscoRoomsResponse>} Updates details for a room, by ID.
   */
  static updateRoom = async (
    payload: ICiscoRoomIdPayload,
    accessToken: string,
    body: ICiscoUpdateRoomPayload
  ): Promise<ICiscoRoomsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.put(`${API_ENDPOINT}/${stringify({ ...payload })}`, body, config);
    console.log("Update a Room: ", data);
    return data;
  };

  /**
   * Delete a Room.
   * @param {ICiscoRoomIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoDeleteResponse>} Deletes a room, by ID..
   */
  static deleteRoom = async (payload: ICiscoRoomIdPayload, accessToken: string): Promise<ICiscoDeleteResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.rooms;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Delete a Room: ", data);
    return data;
  };

  /**
   * List Teams.
   * @param {ICiscoListTeamsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoTeamsResponse>} Lists teams to which the authenticated user belongs..
   */
  static listTeams = async (payload: ICiscoListTeamsPayload, accessToken: string): Promise<ICiscoTeamsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.teams;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Teams: ", data);
    return data;
  };

  /**
   * Create a Team.
   * @param {ICiscoCreateTeamPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoRoomsResponse>} Creates a team.
   */
  static CreateTeam = async (payload: ICiscoCreateTeamPayload, accessToken: string): Promise<ICiscoTeamsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.teams;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, payload, config);
    console.log("Create a Team: ", data);
    return data;
  };

  /**
   * Get Team Details.
   * @param {ICiscoTeamIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoTeamsResponse>} Shows details for a team, by ID..
   */
  static getTeamDetails = async (payload: ICiscoTeamIdPayload, accessToken: string): Promise<ICiscoTeamsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.teams;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Get Team Details: ", data);
    return data;
  };

  /**
   * Update a Team.
   * @param {ICiscoTeamIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @param {ICiscoCreateTeamPayload} body The request data
   * @returns {Promise<ICiscoTeamsResponse>} Updates details for a team, by ID.
   */
  static updateTeam = async (
    payload: ICiscoTeamIdPayload,
    accessToken: string,
    body: ICiscoCreateTeamPayload
  ): Promise<ICiscoTeamsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.teams;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.put(`${API_ENDPOINT}/${stringify({ ...payload })}`, body, config);
    console.log("Update a Team: ", data);
    return data;
  };

  /**
   * Delete a Team.
   * @param {ICiscoTeamIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoDeleteResponse>} Deletes a team, by ID..
   */
  static deleteTeam = async (payload: ICiscoTeamIdPayload, accessToken: string): Promise<ICiscoDeleteResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.teams;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Delete a Team: ", data);
    return data;
  };

  /**
   * List Reports.
   * @param {ICiscoListReportsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoReportsResponse>} Lists all reports..
   */
  static listReports = async (payload: ICiscoListReportsPayload, accessToken: string): Promise<ICiscoReportsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.reports;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Reports: ", data);
    return data;
  };

  /**
   *Create a Report.
   * @param {ICiscoCreateReportPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoCreatedReportsResponse>} Creates a report.
   */
  static CreateReport = async (payload: ICiscoCreateReportPayload, accessToken: string): Promise<ICiscoCreatedReportsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.reports;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, payload, config);
    console.log("Create a Report: ", data);
    return data;
  };

  /**
   * Get Report Details
   * @param {ICiscoReportIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoReportsResponse>} Shows details for a report, by report ID.
   */
  static getReportDetails = async (payload: ICiscoReportIdPayload, accessToken: string): Promise<ICiscoReportsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.reports;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Get Report Details: ", data);
    return data;
  };

  /**
   * Delete a Report.
   * @param {ICiscoReportIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoDeleteResponse>} Remove a report from the system.
   */
  static deleteReport = async (payload: ICiscoReportIdPayload, accessToken: string): Promise<ICiscoDeleteResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.reports;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Delete a Report: ", data);
    return data;
  };

  /**
   * List Meeting Invitees.
   * @param {ICiscoMeetingInviteesPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingInviteesResponse>} Lists meeting invitees for a meeting.
   */
  static meetingInvitees = async (payload: ICiscoMeetingInviteesPayload, accessToken: string): Promise<ICiscoMeetingInviteesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingInvitees;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Meeting Invitees: ", data);
    return data;
  };

  /**
   * Create a Meeting Invitee.
   * @param {ICiscoCreateMeetingInviteesPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingInviteesResponse>} Invites a person to attend a meeting.
   */
  static createMeetingInvitee = async (
    payload: ICiscoCreateMeetingInviteesPayload,
    accessToken: string
  ): Promise<ICiscoMeetingInviteesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingInvitees;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, payload, config);
    console.log("Create a Meeting Invitee: ", data);
    return data;
  };

  /**
   * Get a Meeting Invitee.
   * @param {ICiscoGetMeetingInviteesPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingInviteesResponse>} Retrieves details for a meeting invitee.
   */
  static getMeetingInvitees = async (
    payload: ICiscoGetMeetingInviteesPayload,
    accessToken: string
  ): Promise<ICiscoMeetingInviteesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingInvitees;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("Get a Meeting Invitee: ", data);
    return data;
  };

  /**
   * Update a Meeting Invitee.
   * @param {ICiscoMeetingInviteesIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @param {ICiscoUpdateMeetingInviteesPayload} body The request data
   * @returns {Promise<ICiscoMeetingInviteesResponse>} Updates details for a meeting invitee identified by a meetingInviteeId.
   */
  static updateMeetingInvitees = async (
    payload: ICiscoMeetingInviteesIdPayload,
    accessToken: string,
    body: ICiscoUpdateMeetingInviteesPayload
  ): Promise<ICiscoMeetingInviteesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingInvitees;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.put(`${API_ENDPOINT}/${stringify({ ...payload })}`, body, config);
    console.log("Update a Meeting Invitee: ", data);
    return data;
  };

  /**
   * Delete a Meeting Invitee.
   * @param {ICiscoDeleteMeetingInviteesPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoDeleteMeetingInviteesResponse>} Removes a meeting invitee identified by a meetingInviteeId.
   */
  static deleteMeetingInvitee = async (
    payload: ICiscoDeleteMeetingInviteesPayload,
    accessToken: string
  ): Promise<ICiscoDeleteMeetingInviteesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingInvitees;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Delete a Meeting Invitee: ", data);
    return data;
  };

  /**
   * List Meetings of a Meeting Series.
   * @param {ICiscoMeetingSeriesPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingSeriesResponse>} Lists scheduled meeting and meeting instances of a meeting series identified by meetingSeriesId.
   */
  static meetingSeries = async (payload: ICiscoMeetingSeriesPayload, accessToken: string): Promise<ICiscoMeetingSeriesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetings;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Meetings of a Meeting Series: ", data);
    return data;
  };

  /**
   * Get a Meeting.
   * @param {string} meetingId The request data
   * @param {string} accessToken The request data
   * @param {ICiscoMeetingPayload} payload The request data
   * @returns {Promise<ICiscoMeetingResponseItem>} Retrieves details for a meeting with a specified meeting ID.
   */
  static getMeeting = async (
    meetingId: string,
    accessToken: string,
    payload?: ICiscoMeetingPayload
  ): Promise<ICiscoMeetingResponseItem> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetings;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let result = null;
    if (payload) {
      result = await axios.get(`${API_ENDPOINT}/${meetingId}/${stringify({ ...payload })}`, config);
    } else {
      result = await axios.get(`${API_ENDPOINT}/${meetingId}`, config);
    }

    console.log("Get a Meeting: ", result.data);
    return result.data;
  };

  /**
   * Get a Meeting in Progress.
   * @param {string} accessToken The request data
   * @param {ICiscoMeetingInProgressPayload} payload The request data
   * @returns {Promise<ICiscoMeetingInProgressResponse>} Retrieves details for a meeting with a specified meeting ID.
   */
  static getMeetingInProgress = async (
    accessToken: string,
    payload?: ICiscoMeetingInProgressPayload
  ): Promise<ICiscoMeetingInProgressResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetings;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await axios.get(`${API_ENDPOINT}?${stringify({ ...payload })}`, config);
    console.log("Get a Meeting in Progress: ", data);
    return data;
  };

  /**
   * Get a List Meetings.
   * @param {ICiscoListMeetingPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingSeriesResponse>} Retrieves details for meetings with a specified meeting number, web link, meeting type, etc.
   */
  static getListMeeting = async (payload: ICiscoListMeetingPayload, accessToken: string): Promise<ICiscoMeetingSeriesResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetings;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("Get a List Meetings: ", data);
    return data;
  };

  /**
   * Get Person Details.
   * @param {ICiscoPersonDetailsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoPersonDetailsResponse>} Shows details for a person, by ID.
   */
  static getPersonDetails = async (payload: ICiscoPersonDetailsPayload, accessToken: string): Promise<ICiscoPersonDetailsResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.people;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("Get Person Details: ", data);
    return data;
  };

  /**
   * Get Meeting Participant Details.
   * @param {ICiscoMeetingParticipantDetailsPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoMeetingParticipantDetailsResponse>} Get a meeting participant details of a live or post meeting.
   */
  static getMeetingParticipantDetails = async (
    payload: ICiscoMeetingParticipantDetailsPayload,
    accessToken: string
  ): Promise<ICiscoMeetingParticipantDetailsResponse> => {
    // const item: ICiscoMeetingParticipantDetailsResponseItem = {
    //   id: '',
    //   orgId: '',
    //   host: false,
    //   coHost: false,
    //   spaceModerator: false,
    //   email: '',
    //   displayName: '',
    //   invitee: false,
    //   video: '',
    //   muted: false,
    //   state: MeetingParticipantDetailsStateEnum.Lobby,
    //   siteUrl: '',
    //   meetingId: '',
    //   hostEmail: '',
    //   devices: [],
    // };
    // const defaultResponse: ICiscoMeetingParticipantDetailsResponse = { items: Array<ICiscoMeetingParticipantDetailsResponseItem>() };

    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.meetingParticipants;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    //  try {
    const { data } = await axios.get(`${API_ENDPOINT}?${stringify({ ...payload })}`, config);
    console.log("Get Meeting Participant Details: ", data);
    return data;
    /*  } catch (err) {
      if (request.isAxiosError(err) && err.response) {
        console.log((err.response?.data).message);
        return (err.response?.data).message;
      }
    }
    return null;*/
  };

  /**
   * List Webhooks.
   * @param {ICiscoWebhooksPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoWebhooksResponse>} List all of your webhooks.
   */
  static getWebhooks = async (payload: ICiscoWebhooksPayload, accessToken: string): Promise<ICiscoWebhooksResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.webhooks;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}${stringify({ ...payload })}`, config);
    console.log("List Webhooks: ", data);
    return data;
  };

  /**
   * Create a Webhook.
   * @param {ICiscoCreateWebhooksPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoCreateWebhooksResponse>} Creates a webhook.
   */
  static createWebhooks = async (payload: ICiscoCreateWebhooksPayload, accessToken: string): Promise<ICiscoCreateWebhooksResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.webhooks;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(`${API_ENDPOINT}`, payload, config);
    console.log("Create a Webhook: ", data);
    return data;
  };

  /**
   * Get Webhook Details.
   * @param {ICiscoWebhooksIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoCreateWebhooksResponse>} Shows details for a webhook, by ID.
   */
  static getwWbhooksDetails = async (payload: ICiscoWebhooksIdPayload, accessToken: string): Promise<ICiscoCreateWebhooksResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.webhooks;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Get Webhook Details: ", data);
    return data;
  };

  /**
   * Delete a Webhook.
   * @param {ICiscoWebhooksIdPayload} payload The request data
   * @param {string} accessToken The request data
   * @returns {Promise<ICiscoDeleteResponse>} Deletes a webhook, by ID.
   */
  static deleteWebhooks = async (payload: ICiscoWebhooksIdPayload, accessToken: string): Promise<ICiscoDeleteResponse> => {
    const API_ENDPOINT = CiscoWebexEmbededAppAPI.routes.webhooks;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(`${API_ENDPOINT}/${stringify({ ...payload })}`, config);
    console.log("Delete a Webhook: ", data);
    return data;
  };
}
