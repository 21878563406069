/*
 * Filename:       SmartSheetAPI.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */
import { GrantTypeEnum } from "@models";

export enum SourceTypeEnum {
  REPORT = "report",
  SHEET = "sheet",
  SIGHT = "sight",
  TEMPLATE = "template",
}

export enum AccessLevelEnum {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  EDITOR_SHARE = "EDITOR_SHARE",
  OWNER = "OWNER",
  VIEWER = "VIEWER",
}

export enum ShareTypeEnum {
  USER = "USER",
  GROUP = "GROUP",
}

export enum AttachmentTypeEnum {
  BOX_COM = "BOX_COM",
  DROPBOX = "DROPBOX",
  EGNYTE = "EGNYTE",
  EVERNOTE = "EVERNOTE",
  FILE = "FILE",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  LINK = "LINK",
  ONEDRIVE = "ONEDRIVE",
}

export enum AttachmentSubTypeEnum {
  FOLDER = "FOLDER",
  DOCUMENT = "DOCUMENT",
  DRAWING = "DRAWING",
  PDF = "PDF",
  PRESENTATION = "PRESENTATION",
  SPREADSHEET = "SPREADSHEET",
}

export enum ColumnTypeEnum {
  ABSTRACT_DATETIME = "ABSTRACT_DATETIME",
  CHECKBOX = "CHECKBOX",
  CONTACT_LIST = "CONTACT_LIST",
  DATE = "DATE",
  DATETIME = "DATETIME",
  DURATION = "DURATION",
  MULTI_CONTACT_LIST = "MULTI_CONTACT_LIST",
  MULTI_PICKLIST = "MULTI_PICKLIST",
  PICKLIST = "PICKLIST",
  PREDECESSOR = "PREDECESSOR",
  TEXT_NUMBER = "TEXT_NUMBER",
}

export enum DayEnum {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export enum ProofTypeEnum {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  MIXED = "MIXED",
  NONE = "NONE",
  VIDEO = "VIDEO",
}

export enum SheetUserPermissionEnum {
  ADMIN = "ADMIN",
  READ_DELETE = "READ_DELETE",
  READ_ONLY = "READ_ONLY",
  READ_WRITE = "READ_WRITE",
}

export interface IIndexResult {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  data: Array<any>;
}

export interface IError {
  refId: string;
  errorCode: number;
  message: string;
}

export interface IBulkItemFailure {
  rowId: number;
  error: IError;
  index: number;
}

export interface IResult {
  failedItems: IBulkItemFailure[];
  message: string;
  result: Object;
  resultCode: number;
  version: number;
}

export interface ISheetUser {
  name: string;
  email: string;
}
export interface ISource {
  id: number;
  type: SourceTypeEnum.REPORT | SourceTypeEnum.SHEET | SourceTypeEnum.SIGHT | SourceTypeEnum.TEMPLATE;
}

export interface IAttachment {
  id: number;
  parentId: number;
  attachmentType: AttachmentTypeEnum;
  attachmentSubType: AttachmentSubTypeEnum;
  mimeType: string;
  parentType: string;
  createdAt: string;
  createdBy: ISheetUser;
  name: string;
  sizeInKb: number;
  url: string;
  urlExpiresInMillis: number;
}

export interface IAutoNumberFormat {
  fill: string;
  prefix: string;
  startingNumber: number;
  suffix: string;
}

export interface IContactOption {
  email: string;
  name: string;
}

export interface IHyperlink {
  reportId: number;
  sheetId: number;
  sightId: number;
  url: string;
}

export interface IImage {
  id: string;
  altText: string;
  height: number;
  width: number;
}

export interface ICellLink {
  columnId: number;
  rowId: number;
  sheetId: number;
  sheetName: string;
  status: string;
}

export interface IObjectValue {
  objectType: ColumnTypeEnum;
}

export interface IColumn {
  id: number;
  systemColumnType: string;
  type: ColumnTypeEnum;
  autoNumberFormat?: IAutoNumberFormat;
  contactOptions?: IContactOption[];
  description: string;
  format: string;
  formula: string;
  hidden: boolean;
  index: number;
  locked: boolean;
  lockedForUser: boolean;
  options: string[];
  primary: boolean;
  symbol: string;
  tags: string[];
  title: string;
  validation: boolean;
  version: number;
  width: number;
}

export interface ICrossSheetReferences {
  id: number;
  endColumnId: number;
  endRowId: number;
  sourceSheetId: number;
  startColumnId: number;
  startRowId: number;
  name: string;
  status: string;
}

export interface IComment {
  id: number;
  discussionId?: number;
  attachments: IAttachment[];
  createdAt: string;
  createdBy: ISheetUser;
  modifiedAt: string;
  text: string;
}

export interface IDiscussion {
  id: number;
  parentId: number;
  parentType: string;
  accessLevel: AccessLevelEnum;
  commentAttachments: IAttachment[];
  commentCount: number;
  comments: IComment[];
  createdBy: ISheetUser;
  lastCommentedAt: string;
  lastCommentedUser: ISheetUser;
  readOnly: boolean;
  title: string;
}

export interface IProjectSettings {
  lengthOfDay: number;
  nonWorkingDays: string[];
  workingDays: DayEnum[];
}

export interface IProof {
  id: number;
  originalId: number;
  attachments: IAttachment[];
  discussions: IDiscussion[];
  isCompleted: boolean;
  lastUpdatedAt: string;
  lastUpdatedBy: ISheetUser;
  name: string;
  proofRequestUrl: string;
  proofType: ProofTypeEnum;
  version: number;
}

export interface ICell {
  columnId: number;
  columnType: ColumnTypeEnum;
  conditionalFormat: string;
  displayValue: string;
  format: string;
  formula: string;
  hyperlink: IHyperlink;
  image: IImage;
  linkInFromCell: ICellLink;
  linksOutToCells: ICellLink[];
  objectValue: IObjectValue;
  overrideValidation: boolean;
  strict: boolean;
  value: string | number | boolean | null;
}

export interface IRow {
  id: number;
  sheetId: number;
  accessLevel: AccessLevelEnum;
  attachments: IAttachment[];
  cells: any[];
  columns: IColumn[];
  conditionalFormat: string;
  createdAt: string;
  createdBy: ISheetUser;
  discussions: IDiscussion[];
  expanded: boolean;
  filteredOut: boolean;
  format: string;
  inCriticalPath: boolean;
  locked: boolean;
  lockedForUser: boolean;
  modifiedAt: string;
  modifiedBy: ISheetUser;
  permalink: string;
  rowNumber: number;
  version: number;
}

export interface ISheetSummary {
  fields: string[] | Object[];
}

export interface ISheetUserPermissions {
  summaryPermissions: SheetUserPermissionEnum;
}

export interface ISheetUserSettings {
  criticalPathEnabled: boolean;
  displaySummaryTasks: boolean;
}

export interface IShareObject {
  id: string;
  groupId?: number | null;
  userId?: number | null;
  type: ShareTypeEnum;
  accessLevel: AccessLevelEnum;
  ccMe?: boolean;
  createdAt: string;
  email?: string;
  message?: string;
  subject?: string;
  modifiedAt?: string;
  name?: string;
  scope: string;
}

export interface ISheet {
  id: number;
  fromId: number | null;
  ownerId: number;
  accessLevel: AccessLevelEnum;
  attachments: IAttachment[];
  columns: IColumn[];
  createdAt: string;
  crossSheetReferences: ICrossSheetReferences[];
  dependenciesEnabled: boolean;
  discussions: IDiscussion[];
  effectiveAttachmentOptions: AttachmentTypeEnum[];
  favorite: boolean;
  ganttEnabled: boolean;
  hasSummaryFields: boolean;
  modifiedAt: string;
  name: string;
  owner: string;
  permalink: string;
  projectSettings: IProjectSettings;
  proofs: IProof[];
  readOnly: boolean;
  resourceManagementEnabled: boolean;
  rows: IRow[];
  showParentRowsForFilters: boolean;
  source: ISource;
  summary: ISheetSummary;
  totalRowCount: number;
  userPermissions: ISheetUserPermissions;
  userSettings: ISheetUserSettings;
  version: number;
  workspace: {
    id: number;
    name: string;
  };
}

export interface ISearchResultItem {
  objectId: number;
  parentObjectId?: number;
  contextData: Array<any>;
  objectType: string;
  parentObjectName?: string;
  parentObjectType?: string;
  proofUrl?: string;
  text: string;
}

export interface ISearchResult {
  results: ISearchResultItem[];
  totalCount: number;
}

export interface IPagingQueryStringParams {
  includeAll?: boolean;
  page?: number;
  pageSize?: number;
}

export interface ITokenRequest {
  code?: string;
  client_id: string;
  client_secret?: string | null;
  grant_type: GrantTypeEnum;
  hash?: string | null;
  refresh_token?: string;
}

export interface IListReportsItem {
  id: number;
  accessLevel: string;
  name: string;
  permalink: string;
  createdAt: string;
  modifiedAt: string;
}

export interface IListDashboardsItem {
  id: number;
  accessLevel: string;
  name: string;
  permalink: string;
  createdAt: string;
  modifiedAt: string;
}
export interface IListReportsPayload extends IPagingQueryStringParams {
  include?: string;
  modifiedSince?: string | number;
}
export interface IListReportsResponse extends IIndexResult {
  data: IListReportsItem[];
}
export interface IListSheetsItem {
  id: number;
  accessLevel: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  permalink: string;
  source?: ISource;
  version?: number;
  permissions?: AccessLevelEnum;
}

export interface IListSheetsPayload extends IPagingQueryStringParams {
  include?: string;
  modifiedSince?: string | number;
}

export interface IListSheetsResponse extends IIndexResult {
  data: IListSheetsItem[];
}

export interface IShareSheetParams {
  sendEmail?: boolean;
}

export interface IShareSheetPayload {
  accessLevel: AccessLevelEnum;
  ccMe?: boolean;
  email?: string;
  groupId?: number;
  message?: string;
  subject?: string;
}

export interface IShareSheetResponse extends IResult {
  result: IShareObject | IShareObject[];
}

export interface IListSheetSharesParams extends IPagingQueryStringParams {
  include?: string;
}

export interface IListSheetSharesResponse extends IIndexResult {
  data: IShareObject[];
}

export interface IUpdateSheetSharePayload {
  accessLevel: string;
}

export interface IGetSheetParams {
  include?: string;
  exclude?: string;
  columnIds?: string;
  filterId?: string | number | null;
  ifVersionAfter?: number;
  level?: number;
  page?: number;
  pageSize?: number;
  rowIds?: string;
  rowNumbers?: string;
  rowsModifiedSince?: string;
}

export interface ISearchParams {
  query: string;
  // include?: {
  //   favorite?: boolean;
  //   parentObjectFavorite?: boolean;
  // };
  location?: string;
  modifiedSince?: string;
  scopes?: string;
}

export interface IListingDashboardObject {
  id: number;
  accessLevel: AccessLevelEnum;
  createdAt: string;
  modifiedAt: string;
  name: string;
  permalink: string;
}

export interface IListDashboardParams extends IPagingQueryStringParams {
  modifiedSince?: string;
}

export interface IListDashboardResponse extends IIndexResult {
  data: IListingDashboardObject[];
}

export interface IListMeetingParticipantsResponse {
  meetingId: number;
  emails: Array<string>;
}

export interface IListingRecentObject {
  type: string;
  id: number;
  pinned: boolean;
  openedAt: number;
  pinnedAt: number;
}
export interface IListRecentsResponse extends IIndexResult {
  data: IListingRecentObject[];
}
