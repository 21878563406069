import { DataDogAPI } from "@services";

export const useDataDog = () => {
  const submitUserLoginMetric = () => {
    const payload = {
      series: [
        {
          metric: "webex.login",
          type: "count",
          points: [[new Date().getTime() / 1000, 1]],
          tags: ["webex-prod", "webex-custom"],
        },
      ],
    };

    DataDogAPI.submitMetric(payload);
  }

  const submitAssetShareMetric = () => {
    const payload = {
      series: [
        {
          metric: "webex.assetShare",
          type: "count",
          points: [[new Date().getTime() / 1000, 1]],
          tags: ["webex-prod", "webex-custom"],
        },
      ],
    };

    DataDogAPI.submitMetric(payload);
  }

  return {
    submitUserLoginMetric, 
    submitAssetShareMetric,
  };
}