import React from "react";
import { useHistory } from "react-router-dom";
import { useCiscoAPI, useSmartSheetsAPI } from "@hooks";
import { AppContext } from "@contexts";

interface ILogin {
  authSmartsheet: (authCode: string) => Promise<void>;
  authCisco: (authCode: string) => Promise<void>;
  validateSmartsheet: () => Promise<boolean>;
  validateCisco: (token: string) => Promise<boolean>;
}

export const useLogin = (): ILogin => {
  const context = React.useContext(AppContext);
  const { getAccessToken: getCiscoAccessToken, getAPIRooms } = useCiscoAPI();
  const { getToken: getSmartSheetToken, getPaginatedSheets } = useSmartSheetsAPI();
  const history = useHistory();

  // authorize with smartsheets
  const authSmartsheet = async (authCode: string) => {
    try {
      const data = await getSmartSheetToken(authCode)
      const { access_token: smartSheetToken } = data;
      localStorage.setItem("smartsheet_access_token", smartSheetToken);
      context.setAppAuthorized(true);
      
    } catch (e) {
      
      console.error("FAILURE: Smartsheet Token", e);
    }
  };

  const authCisco = async (authCode: string) => {
    try {
      const data = await getCiscoAccessToken(authCode);
      const { access_token: ciscoAccessToken } = data;
      localStorage.setItem("cisco_access_token", ciscoAccessToken);

      context.setCiscoToken(ciscoAccessToken);
      context.setAppAuthorized(true);
      if (context.webex?.handleGetUser) {
        context.webex?.handleGetUser();
        context.webex?.handleGetMeeting();
      }
      
    } catch (e) {
      console.error("failure to get cisco access token", e);
    }
  };

  const validateSmartsheet = async () => {
    try {
      await getPaginatedSheets({
        page: 1,
        pageSize: 1,
      })
      return true;
    } catch (e) {
      console.error("smartsheet token invalid", e);
      return false;
    }
  }; 

  const validateCisco = async (token: string) => {
    try {
      await getAPIRooms(token)
      return true;
    } catch (e) {
      console.error("cisco token invalid", e);
      return false;
    }
  }; 

  return { 
    authSmartsheet, 
    authCisco, 
    validateSmartsheet, 
    validateCisco 
  };
}