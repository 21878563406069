import React, { useEffect, useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Button, TextField, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { AppContext, typeApplicationContext } from "@contexts";
import { SmartSheetsAPI } from "@services";
import { useSmartSheetsAPI } from "@hooks";
import { RadioPicker } from "@components";
import { SmartSheetLogo, SheetIcon, DashboardIcon, ReportIcon } from "@assets";
import { AccessLevelEnum, ICiscoMeetingParticipantDetailsResponseItem } from "@models";

import "./SheetPermissionError.scss";
import { checkCannotShare, updateRecentSheets } from "@helpers";

const { REACT_APP_SMARTSHEET_SHARE_URL } = process.env;

const permissionsOptions = [
  {
    label: "Admin",
    value: "OWNER",
    name: "admin",
  },
  {
    label: "Editor",
    value: "EDITOR",
    name: "editor",
  },
  {
    label: "Commenter",
    value: "EDITOR_SHARE",
    name: "commenter",
  },
  {
    label: "Viewer",
    value: "VIEWER",
    name: "viewer",
  },
];

export const SheetPermissionError: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<AccessLevelEnum>();
  const { getSheet, getReport, getDashboard } = useSmartSheetsAPI();
  const history = useHistory();

  const context: typeApplicationContext = useContext(AppContext);

  // const blobToBase64 = (blob: Blob): Promise<string> => {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result + "");
  //     reader.readAsDataURL(blob);
  //   });
  // };

  // const setSheetPermissions = async () => {
  //   const emails = context.meetingUsers.map((user: ICiscoMeetingParticipantDetailsResponseItem) => user.email);
  //   const validEmails = emails.filter((mail) => validateEmail(mail));

  //   if (context.activeSheet && validEmails.length && selectedOption) {
  //     console.log("sharing sheet to", validEmails);
  //     await shareSheet(context.activeSheet?.id, validEmails, selectedOption).catch((err) => console.log(err));
  //   }

  //   const sheetId = context.activeSheet?.permalink.split("/").pop();
  //   const jsonData = {
  //     et: 3,
  //     doid: sheetId,
  //   };
  //   const blob = new Blob([JSON.stringify(jsonData)], {
  //     type: "application/json",
  //   });
  //   let base64Data = await blobToBase64(blob);
  //   base64Data = base64Data.split(",")[1];

  //   const embeddedURL = `https://app.smartsheet.com/b/embed/sheets/${base64Data}`;
  //   const shareURL = `${REACT_APP_SMARTSHEET_SHARE_URL}?url=${embeddedURL}`;
  //   console.log("sharing url", shareURL);
  //   context.webex.handleSetShareUrl(shareURL);
  //   window.location.assign(shareURL);
  // };

  // const handleOptionsSelection = (selectedPermissionLevel?: any) => {
  //   let selectedOptions: AccessLevelEnum;

  //   switch (selectedPermissionLevel) {
  //     case "OWNER":
  //       selectedOptions = AccessLevelEnum.ADMIN;
  //       break;
  //     case "EDITOR":
  //       selectedOptions = AccessLevelEnum.EDITOR;
  //       break;
  //     case "EDITOR_SHARE":
  //       selectedOptions = AccessLevelEnum.EDITOR_SHARE;
  //       break;
  //     case "VIEWER":
  //       selectedOptions = AccessLevelEnum.VIEWER;
  //       break;
  //     default:
  //       selectedOptions = AccessLevelEnum.VIEWER;
  //   }
  //   setSelectedOption(selectedOptions);
  // };

  // const validateEmail = (email: string) => {
  //   const re =
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // };


  useEffect(() => {
    if (context.activeSheet && !checkCannotShare(context.activeSheet?.accessLevel || "")) {
      history.push("/permissions");
    }

    if (context.activeDashboard && !checkCannotShare(context.activeDashboard?.accessLevel || "")) {
      history.push("/permissions");
    }

    if (context.activeReport && !checkCannotShare(context.activeReport?.accessLevel || "")) {
      history.push("/permissions");
    }
  }, [context.activeSheet, context.activeDashboard, context.activeReport]);

  const onRefresh = async () => {
    let data;

    if (context.activeSheet) {
      data = await getSheet(context?.activeSheet?.id || 0);
      context.setActiveSheet(data);
    }

    if (context.activeReport) {
      data = await getReport(context?.activeReport?.id || 0);
      context.setActiveReport(data);
    }

    if (context.activeDashboard) {
      data = await getDashboard(context?.activeDashboard?.id || 0);
      context.setActiveDashboard(data);
    }

    if (data) updateRecentSheets(data);
  };

  const goBack = () => {
    context.setActiveSheet(undefined);
    context.setActiveReport(undefined);
    context.setActiveDashboard(undefined);
    history.push("/sheet");
  };

  return (
    <div id="permission-error-page">
      <div className="header">
        <img src={SmartSheetLogo} />
        <div className="title-container">
          <span className="title bold font-style default-dark-font-1">{`Step 2: `}</span>
          <span className="title">Set Permissions</span>
        </div>
        <div className="file-info">
          <div className="image-preview">
            {context.activeSheet && <img src={SheetIcon} />}
            {context.activeReport && <img src={ReportIcon} />}
            {context.activeDashboard && <img src={DashboardIcon} />}
          </div>
          <div className="sheet-name">
            {context.activeSheet && <span className="default-dark-font-4">{context.activeSheet && context.activeSheet.name}</span>}
            {context.activeReport && <span className="default-dark-font-4">{context.activeReport && context.activeReport.name}</span>}
            {context.activeDashboard && <span className="default-dark-font-4">{context.activeDashboard && context.activeDashboard.name}</span>}
            <span className="default-grey-font-2">
              {context.activeSheet && "Sheet"}
              {context.activeReport && "Report"}
              {context.activeDashboard && "Dashboard"}
            </span>
          </div>
        </div>
      </div>

      <div className="error-message">
        You currently do not have permission to share this file. Contact the file owner for "Editor - can share" permissions.
      </div>

      <div id="button-controls">
        <Button
          variant="contained"
          onClick={() => goBack()}
          sx={{
            color: "#444444",
            width: 150,
            fontWeight: "bold",
            backgroundColor: "#EEEEEE",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "#FFFFFF",
            width: 150,
            fontWeight: "bold",
            backgroundColor: "#3576BA",
            "&:hover": {
              backgroundColor: "#5098e5",
            },
          }}
          onClick={onRefresh}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
};
