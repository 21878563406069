import { useEffect } from "react";
export const ShareComponent = () => {
  const { REACT_APP_SMARTSHEET_SHARING_CLIENT_ID: clientId } = process.env;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const nextURL = searchParams.get("url"),
      authCode = searchParams.get("code");

    if (nextURL) {
      localStorage.setItem("nextURL", nextURL);
      window.location.assign(
        `https://app.smartsheet.com/b/authorize?response_type=code&client_id=${clientId}&scope=READ_SHEETS WRITE_SHEETS SHARE_SHEETS READ_SIGHTS SHARE_SIGHTS`
      );
    } else if (authCode) {
      // To Do: Insert Error page URLs
      window.location.assign(localStorage.getItem("nextURL") || "");
    }
  }, []);

  return null;
};
