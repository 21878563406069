import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";

import { AppContext, typeApplicationContext } from "@contexts";
import { BackArrow } from "@assets";

import "./PreviewPage.scss";
import { SignOutButton } from "@components";

export const PreviewPage = () => {
  const context: typeApplicationContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!context.sharingURL) {
      history.push("/sheet");
      return;
    }
  }, [context.sharingURL]);

  return (
    <div id="preview-page">
      <div className="header-container">
        <button 
          className="select-different-btn"
          onClick={() => {
            context.setActiveSheet(undefined);
            history.push("/sheet");
          }}
        >
          <img src={BackArrow} />
          Select a different file
        </button>
        <SignOutButton/>
      </div>
      
      <iframe src={context.sharingURL} referrerPolicy="no-referrer"></iframe>
    </div>
  );
};
