/*
 * Filename:       SmartSheetsAPIUtils.ts
 * Classification: UNCLASSIFIED
 *
 * Copyright (C) 2021 SmartSheets, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by SmartSheets, Inc.
 *
 * SmartSheets Proprietary
 * The Proprietary Information provided herein is proprietary to SmartSheets and
 * must be protected from further distribution and use.  Disclosure to others,
 * use or copying without express written authorization of SmartSheets, is strictly
 * prohibited
 *
 */

import { AccessLevelEnum } from "@models";

/**
 * Format a string.
 * @param {string} baseString The base string which contains {} values
 * @param {Array} values The object containing actual values
 * @returns {string} The properly formated string.
 */

export const formatString = (baseString: string, values: Array<any>) => {
  return baseString.replace(/{(\d+)}/g, (match: string, number: number) => {
    return typeof values[number] != "undefined" ? values[number] : match;
  });
};

export const checkTokenExpiration = (tokenExpiration: number): boolean => {
  return Date.now() >= tokenExpiration * 1000 ? true : false;
};

export const clearLocalStorage = () => {
  localStorage.removeItem("auth_code");
  localStorage.removeItem("smartsheet_recent_sheets");
  localStorage.removeItem("smartsheet_access_token");
  localStorage.removeItem("cisco_access_token");
};

export const checkCannotShare = (accessLevel: string) => {
  if (accessLevel === AccessLevelEnum.EDITOR || accessLevel === AccessLevelEnum.VIEWER) return true;
};

export const compareAccessLevels = (left: string, right: string) => {
  const levels = ["VIEWER", "EDITOR", "EDITOR_SHARE", "OWNER", "ADMIN"];
  const leftIdx = levels.indexOf(left),
    rightIdx = levels.indexOf(right);

  console.log("left,right", leftIdx, rightIdx);

  if (leftIdx > rightIdx) return 1;
  return leftIdx === rightIdx ? 0 : -1;
};

