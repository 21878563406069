const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result + "");
    reader.readAsDataURL(blob);
  });
};

export const getEmbeddedURL = async (id: string, type: string) => {
  const jsonData = { et: 3, doid: id }
  const blob = new Blob([JSON.stringify(jsonData)], { type: "application/json", });
  let base64Data = await blobToBase64(blob);
  base64Data = base64Data.split(",")[1];
  const embeddedURL = `https://app.smartsheet.com/b/embed/${type}/${base64Data}`;
  return embeddedURL;
}