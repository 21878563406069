import React from 'react';
import { IListReportsItem, IListSheetsItem } from '@models';
import { SheetIcon, ReportIcon, DashboardIcon } from '@assets';

import './SheetItemComponent.scss';

interface Props {
  data?: IListSheetsItem | IListReportsItem | null;
  type: "Sheet" | "Report" | string;
  onSelect: Function;
}

export const SheetItemComponent: React.FC<Props> = ({ type, data, onSelect }) => {
  return (
    <div className="sheet-item" onClick={() => onSelect(data)}>
      {type === "Sheet" && <img src={SheetIcon} className="icon" />}
      {type === "Report" && <img src={ReportIcon} className="icon" />}
      {type === "Dashboard" && <img src={DashboardIcon} className="icon" />}

      <div className="description">
        <h1 className="title">{data?.name}</h1>
        <h1 className="title type">{type}</h1>
      </div>
    </div>
  );
};
