import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import { AppContext, typeApplicationContext } from "@contexts";
import { useSmartSheetsAPI, useDataDog } from "@hooks";
import { RadioPicker, SignOutButton } from "@components";
import { SmartSheetLogo, SheetIcon, ReportIcon, DashboardIcon } from "@assets";
import { compareAccessLevels, getEmbeddedURL } from "@helpers";
import { AccessLevelEnum, IListSheetsItem, IListSheetsResponse, ICiscoMeetingParticipantDetailsResponseItem } from "@models";
import "./SelectSheetPermissions.scss";

const { REACT_APP_SMARTSHEET_SHARE_URL } = process.env;

const permissionsOptions = [
  {
    label: "Admin",
    value: "ADMIN",
    name: "admin",
  },
  {
    label: "Editor - can share",
    value: "EDITOR_SHARE",
    name: "editor",
  },
  {
    label: "Editor - cannot share",
    value: "EDITOR",
    name: "editor",
  },
  {
    label: "Viewer",
    value: "VIEWER",
    name: "viewer",
  },
];

export const SelectSheetPermissions: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<AccessLevelEnum>();
  const [emailAddresses, setEmailAddresses] = useState<Array<string>>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const {
    shareSheet,
    shareReport,
    shareDashboard,
    getAllSheetShares,
    getAllReportShares,
    getAllDashboardShares,
    updateSheetShare,
    updateReportShare,
    updateDashboardShare,
  } = useSmartSheetsAPI();
  const { submitAssetShareMetric } = useDataDog();
  const history = useHistory();

  const context: typeApplicationContext = useContext(AppContext);
  const isSpace = !!localStorage.getItem("spaceId");

  useEffect(() => {
    const emails = context.meetingUsers.map((user: ICiscoMeetingParticipantDetailsResponseItem) => user.email);
    const validEmails = emails.filter((mail) => validateEmail(mail));
    setEmailAddresses(validEmails);
  }, [context.meetingUsers]);

  const setSheetPermissions = async () => {
    let emails = context.meetingUsers.map((user: ICiscoMeetingParticipantDetailsResponseItem) => user.email);
    if (!emails || !emails.length) emails = context.roomUsers;

    const validEmails = emails.filter((mail) => validateEmail(mail));

    let embeddedURL;
    let shareURL;
    if (context.activeSheet && selectedOption) {
      console.log("sharing sheet to", validEmails);
      await shareSheet(context.activeSheet?.id, validEmails, selectedOption).catch((err) => console.log(err));
      const sheetId = context.activeSheet?.permalink.split("/").pop();
      if (!sheetId) return;
      embeddedURL = await getEmbeddedURL(sheetId, "sheets");
      shareURL = `${REACT_APP_SMARTSHEET_SHARE_URL}?url=${embeddedURL}`;
    }

    if (context.activeReport && selectedOption) {
      await shareReport(context.activeReport?.id, validEmails, selectedOption).catch((err) => console.log(err));
      const reportId = context.activeReport?.permalink.split("/").pop();
      if (!reportId) return;
      embeddedURL = await getEmbeddedURL(reportId, "reports");
      shareURL = `${REACT_APP_SMARTSHEET_SHARE_URL}?url=${embeddedURL}`;
    }

    if (context.activeDashboard && selectedOption) {
      const response = await getAllDashboardShares(context.activeDashboard?.id).catch((err) => {
        console.log("error", err);
      });
      console.log("currentDashboardShares", response);
      let newPermissions: string[] = validEmails,
        updatingPermissions: string[] = [];

      let currentPermissions: any = {};

      if (response?.data) {
        newPermissions = [];

        currentPermissions = response?.data.reduce((result: any, shareItem) => {
          result[shareItem.email || ""] = shareItem;
          return result;
        }, {});

        validEmails.forEach((email) => {
          const compareResult =
            currentPermissions[email] && currentPermissions[email].accessLevel
              ? compareAccessLevels(currentPermissions[email].accessLevel, selectedOption)
              : 1;
          if (compareResult === -1) updatingPermissions.push(email);
          else if (compareResult === 1) newPermissions.push(email);
        });
      }

      console.log("newPermissions", newPermissions);
      console.log("updatingPermissions", updatingPermissions);

      // await shareDashboard(context.activeDashboard?.id, validEmails, selectedOption).catch((err) => console.log(err));
      await shareDashboard(context.activeDashboard?.id, newPermissions, selectedOption).catch((err) => console.log(err));
      if (updatingPermissions.length)
        await Promise.all(
          updatingPermissions.map((email) =>
            updateDashboardShare(context.activeDashboard?.id || "", currentPermissions[email].id || "", selectedOption)
          )
        ).catch((err) => console.log(err));
      const dashboardId = context.activeDashboard?.permalink.split("/").pop();
      if (!dashboardId) return;
      embeddedURL = await getEmbeddedURL(dashboardId, "dashboards");
      shareURL = `${REACT_APP_SMARTSHEET_SHARE_URL}?url=${embeddedURL}`;
    }

    if (!embeddedURL) return;
    context.webex.handleSetShareUrl(shareURL);
    context.setSharingURL(embeddedURL);
    await submitAssetShareMetric();

    history.push("/preview");
  };

  const handleOptionsSelection = (selectedPermissionLevel?: any) => {
    let selectedOptions: AccessLevelEnum;

    switch (selectedPermissionLevel) {
      case "ADMIN":
        selectedOptions = AccessLevelEnum.ADMIN;
        break;
      case "EDITOR":
        selectedOptions = AccessLevelEnum.EDITOR;
        break;
      case "EDITOR_SHARE":
        selectedOptions = AccessLevelEnum.EDITOR_SHARE;
        break;
      case "VIEWER":
        selectedOptions = AccessLevelEnum.VIEWER;
        break;
      case "COMMENTER":
        selectedOptions = AccessLevelEnum.VIEWER;
        break;
      default:
        selectedOptions = AccessLevelEnum.VIEWER;
    }
    setSelectedOption(selectedOptions);
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !email.includes("+");
  };

  const goBack = () => {
    context.setActiveSheet(undefined);
    context.setActiveDashboard(undefined);
    context.setActiveReport(undefined);
    setSelectedOption(undefined);
    history.push("/sheet");
  };

  return (
    <div id="sheet-permissions-page">
      <div className="header">
        <div className="logo-container">
          <img src={SmartSheetLogo} />
          <SignOutButton/>
        </div>
        <div className="title-container">
          <span className="title bold font-style default-dark-font-1">{`Step 2: `}</span>
          <span className="title">Set Permissions</span>
        </div>
        <div className="file-info">
          <div className="image-preview">
            {context.activeSheet && <img src={SheetIcon} />}
            {context.activeReport && <img src={ReportIcon} />}
            {context.activeDashboard && <img src={DashboardIcon} />}
          </div>
          <div className="sheet-name">
            {context.activeSheet && <span className="default-dark-font-4">{context.activeSheet && context.activeSheet.name}</span>}
            {context.activeReport && <span className="default-dark-font-4">{context.activeReport && context.activeReport.name}</span>}
            {context.activeDashboard && <span className="default-dark-font-4">{context.activeDashboard && context.activeDashboard.name}</span>}
            <span className="default-grey-font-2">
              {context.activeSheet && "Sheet"}
              {context.activeReport && "Report"}
              {context.activeDashboard && "Dashboard"}
            </span>
          </div>
        </div>
      </div>

      <p>Select the sharing level you would like to assign to the other {isSpace ? "people in this space" : "meeting attendees"}.</p>

      <div>
        <RadioPicker
          name="sheet-permissions"
          defaultValue={"VIEWER"}
          options={context.activeDashboard ? [permissionsOptions[0], permissionsOptions[3]] : permissionsOptions}
          callBack={(e: string) => handleOptionsSelection(e)}
        />
      </div>
      <div id="button-controls">
        <Button
          variant="contained"
          onClick={() => goBack()}
          sx={{
            color: "#444444",
            width: 150,
            fontWeight: "bold",
            backgroundColor: "#EEEEEE",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          Back
        </Button>
        <Button
          disabled={!selectedOption ? true : false}
          variant="contained"
          sx={{
            color: "#FFFFFF",
            width: 150,
            fontWeight: "bold",
            backgroundColor: "#3576BA",
            "&:hover": {
              backgroundColor: "#5098e5",
            },
          }}
          onClick={() => setSheetPermissions()}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
